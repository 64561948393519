import axios from "axios";

/**
 * Submits the housing request form data to the server.
 * @param {ILogement} formData The housing request form data.
 * @returns Promise resolved with the API response or rejected with an error.
 */
const writeLogementUpdateStatus = async (
  logementId: string,
  newStatus: string
) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const url = `${baseUrl}/logement/update/status`;

  try {
    const response = await axios.post(url, {
      headers: {
        "Content-Type": "application/json",
      },
      logementId: logementId,
      newStatus: newStatus,
    });

    return response.data; // You can adjust this return based on how your API responds
  } catch (error) {
    console.log("Error submitting housing request:", error);
    throw error; // You may still want to throw the error to handle it outside this function
  }
};

export default writeLogementUpdateStatus;
