import React, { ChangeEvent, useEffect, useState } from "react";
import {
  FlexColCenter,
  FlexColStart,
  FlexRowCenter,
} from "../../components/UI/Flex/Flex";
import {
  ButtonMQEA,
  ContentWrapper,
  HeroContainer,
  Logo,
  StyledLink,
  Subtitle,
  Title,
} from "../../components/Hero/Hero";
import { Form, InputNeumorphic } from "../../components/UI/Forms/Forms.styled";
import { useTheme } from "../../context/ThemeContext";
import logo from "../../assets/images/folder.webp";
import ButtonNeumorphicRoot from "../../components/UI/ButtonNeumorphic/ButtonNeumorphic.styled";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  loadAuth,
  loadAuthEmpty,
  loadAuthToken,
} from "../../store/applications/auth";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import { Divider } from "../../components/Divider/Divider";

export function logout() {
  window.localStorage.removeItem("uid-mqea");
  window.localStorage.removeItem("auth-mqea");
  return signOut(auth);
}

function Logement() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authData = useAppSelector((state) => state.AuthReducer);
  const [numeroUnique, setNumeroUnique] = useState("");
  // eslint-disable-next-line
  const [isAuth, setIsAuth] = useState(
    false || window.localStorage.getItem("auth-mqea") === "true"
  );
  const [token, setToken] = useState("");

  const handleInput = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    setNumeroUnique(e.target.value);
  };

  useEffect(() => {
    dispatch(loadAuth());
    if (isAuth) {
      onAuthStateChanged(auth, (userCred) => {
        userCred?.getIdToken().then((token) => {
          dispatch(loadAuthToken(token));
          setToken(token);
        });
      });
    }
  }, [token, authData.uid]); // eslint-disable-line

  return (
    <div>
      <ButtonNeumorphicRoot onClick={() => navigate(-1)}>
        <span className="material-icons" style={{ fontSize: "18px" }}>
          arrow_back
        </span>
      </ButtonNeumorphicRoot>

      <HeroContainer theme={theme}>
        <ContentWrapper>
          <Logo src={logo} alt="monquartierenaction logo" />
          <Title theme={theme}>
            Pour un traitement optimisé de votre demande de logement social.
          </Title>
          <Subtitle theme={theme}>
            (1) Veuillez enregistrer votre demande de logement social et
            téléverser les pièces justificatives numérisées. (2) Nous allons
            traiter votre demande et (3) nous rapprocher des services
            compétents.
            <br />
            Nous ne siègeons pas aux commissions d'attribution de logement et
            nous ne pouvons pas vous garantir l'obtention d'un logement.
          </Subtitle>
          <FlexRowCenter>
            <FlexColCenter>
              {isAuth ? (
                authData.userType === "manager" ||
                authData.userType === "admin" ? (
                  <>
                    <StyledLink to="/logement/list" theme={theme}>
                      Toutes les demandes
                    </StyledLink>
                    <Divider />
                    <ButtonMQEA
                      theme={theme}
                      onClick={() =>
                        logout().then(() => {
                          dispatch(loadAuthEmpty());
                          window.location.reload();
                        })
                      }
                    >
                      Déconnexion
                    </ButtonMQEA>
                  </>
                ) : (
                  <>
                    <ButtonMQEA
                      theme={theme}
                      onClick={() =>
                        logout().then(() => {
                          dispatch(loadAuthEmpty());
                          window.location.reload();
                        })
                      }
                    >
                      Déconnexion
                    </ButtonMQEA>
                  </>
                )
              ) : (
                <>
                  <StyledLink to="/connexion" theme={theme}>
                    Connexion
                  </StyledLink>
                  <Divider />
                </>
              )}

              <StyledLink to="/logement/nouveau" theme={theme}>
                Soumettre ma demande
              </StyledLink>
              <Divider />
              <Form action={`/logement/${numeroUnique}`}>
                <InputNeumorphic
                  required
                  placeholder="Numéro Unique 013xxxxxxxxxx"
                  onChange={handleInput}
                />
                <ButtonMQEA type="submit" theme={theme}>
                  Consulter ma demande
                </ButtonMQEA>
              </Form>
              <FlexColStart></FlexColStart>
            </FlexColCenter>
          </FlexRowCenter>
        </ContentWrapper>
      </HeroContainer>
    </div>
  );
}

export default Logement;
