import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  IUserData,
  initialUserData,
  loadAuth,
  loadAuthToken,
} from "../../store/applications/auth";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import { InputNeumorphic, Label } from "../../components/UI/Forms/Forms.styled";
import { ButtonMQEA } from "../../components/Hero/Hero";
import { useTheme } from "../../context/ThemeContext";

const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
`;

const FormContainer = styled.div`
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px; // Adjust the width as needed
`;

const FormTitle = styled.h2`
  color: #333;
  text-align: left;
  margin-bottom: 30px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

// const Label = styled.label`
//   margin-bottom: 5px;
//   font-weight: bold;
//   display: block;
//   color: #555;
// `;

// const Input = styled.input`
//   padding: 10px;
//   border: 1px solid #ddd;
//   border-radius: 5px;
//   margin-bottom: 10px;
//   width: 100%;
// `;

// const Select = styled.select`
//   padding: 10px;
//   border: 1px solid #ddd;
//   border-radius: 5px;
//   margin-bottom: 10px;
//   width: 100%;
// `;

// const SubmitButton = styled.button`
//   padding: 10px 20px;
//   background-color: #4caf50;
//   color: white;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   font-weight: bold;
//   &:hover {
//     background-color: #45a049;
//   }
// `;

// Component
const Profile: React.FC = () => {
  const dispatch = useAppDispatch();
  const authData = useAppSelector((state) => state.AuthReducer);
  const theme = useTheme();
  // eslint-disable-next-line
  const [isAuth, setIsAuth] = useState(
    false || window.localStorage.getItem("auth-mqea") === "true"
  );
  const [token, setToken] = useState("");
  const [userData, setUserData] = useState<IUserData>(initialUserData);

  useEffect(() => {
    console.log(token);
    console.log(authData.uid);
    console.log(isAuth);
    console.log(userData.nom);
    dispatch(loadAuth()).then(() => {
      if (authData && authData.uid) {
        console.log("setUserData");
        setUserData(authData);
      }
    });

    if (isAuth) {
      onAuthStateChanged(auth, (userCred) => {
        userCred?.getIdToken().then((token) => {
          dispatch(loadAuthToken(token));
          setToken(token);
        });
      });
    }
  }, [token, authData.uid, isAuth, userData.nom]); // eslint-disable-line

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle form submission
    console.log(userData);
  };

  return (
    <ProfileContainer>
      <FormContainer>
        <FormTitle>Setting Profile</FormTitle>
        <Form onSubmit={handleSubmit}>
          {/* ... other form groups ... */}
          <FormGroup>
            <Label htmlFor="email">Email</Label>
            <InputNeumorphic
              id="email"
              name="email"
              type="email"
              value={userData.email ? userData.email : authData.email}
              onChange={handleChange}
            />
          </FormGroup>
          {/* ... more inputs based on IUserData fields ... */}
          <FormGroup>
            <Label htmlFor="nom">Nom</Label>
            <InputNeumorphic
              id="nom"
              name="nom"
              type="text"
              value={userData.nom ? userData.nom : authData.nom}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="prenom">Prénom</Label>
            <InputNeumorphic
              id="prenom"
              name="prenom"
              type="text"
              value={userData.prenom ? userData.prenom : authData.prenom}
              onChange={handleChange}
            />
          </FormGroup>
          {/* ... and so on for the rest of the fields ... */}
          <ButtonMQEA theme={theme} type="submit">
            Save Changes
          </ButtonMQEA>
        </Form>
      </FormContainer>
    </ProfileContainer>
  );
};

export default Profile;
