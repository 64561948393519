import styled from "styled-components";

export const FlexRowStart = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
export const FlexRowCenter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const FlexRowEnd = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const FlexRowBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FlexColStart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const FlexColCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const FlexColEnd = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const GridThreeCol = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px; // Adjust the gap to your preference

  @media (max-width: 768px) {
    // Adjust breakpoint as needed
    grid-template-columns: repeat(2, 1fr);
  }
`;
