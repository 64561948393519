import styled from "styled-components";
import mairie from "../../assets/images/mairie-jas.jpeg";

export const LoginBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  min-height: 100vh;

  @media screen and (max-width: 682px) {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }
`;

export const LoginPart1Box = styled.div`
  /* background-image: radial-gradient(ellipse at right, #b2e5f8, transparent),
    radial-gradient(ellipse at right, #f2f3e2, transparent),
    radial-gradient(ellipse at left, #f4b3ef, transparent); */
  background: linear-gradient(
    135deg,
    rgba(61, 71, 217, 0.167) 0%,
    rgba(233, 124, 187, 0.167) 50%,
    rgba(249, 231, 187, 0.167) 100%
  );
  /* 50% center / cover,
    url(${mairie}) transparent; */
  background-size: cover;
  background-position: 50% center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoginLogo = styled.img`
  width: 250px;
`;

export const LoginPart1 = styled.div`
  display: flex;
  backdrop-filter: blur(0.2rem);
  box-shadow: 2px 2px 20px 0 rgb(0 0 0 / 20%),
    -2px -2px 20px 0 rgb(255 255 255 / 20%);
  min-width: 20rem;
  padding: 2rem;
  margin: 1rem;
  border-radius: 1rem;
  z-index: 99;
  font-size: 3rem;
  text-align: center;
`;

export const LoginRoadmapBox = styled.div`
  display: flex;
  box-shadow: 2px 2px 20px 0 rgb(0 0 0 / 20%),
    -2px -2px 20px 0 rgb(255 255 255 / 20%);
  min-width: 20rem;
  padding: 2rem;
  margin: 1rem;
  z-index: 99;
  text-align: left;
`;

export const LoginActionBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
`;

export const LoginAction = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  width: 100%;
`;

export const LoginButton = styled.button`
  background-image: linear-gradient(310deg, #fff, #ececfb);
  box-shadow: 0px 0px 18px rgb(0 0 0 / 20%);
  padding: 0.7rem;
  min-width: calc(50vw - 4rem);
  border-radius: 0.4rem;
  font-weight: 600;
  border: none;
  transition: all 300ms ease-in-out;
  cursor: pointer;

  &:hover {
    font-weight: 700;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 20%);
  }
`;
