import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { amisReducer } from "./applications/AmisReducer";
import { authReducer } from "./applications/auth";
import { fileReducer } from "./applications/FileReducer";
import { postsReducer } from "./applications/postsReducer";
import { UIReducer } from "./applications/UIReducer";
import { logementSlice } from "./applications/logementReducer";

const rootReducer = combineReducers({
  // ClusteringReducer: clusteringStore.reducer,
  // ProjectsReducer: projects.reducer,
  // IndustriesReducer: industries.reducer,
  AuthReducer: authReducer.reducer,
  UIReducer: UIReducer.reducer,
  PostsReducer: postsReducer.reducer,
  AmisReducer: amisReducer.reducer,
  FileReducer: fileReducer.reducer,
  LogementReducer: logementSlice.reducer,
  // SitemapReducer: sitemapDataReducer.reducer,
  // SitemapListReducer: sitemapDataListReducer.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
