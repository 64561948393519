import { createSlice } from "@reduxjs/toolkit";

export interface IFilesReducer {
  files: File[];
  filesDataUrl: string[];
  dataFetch: boolean;
  error: any;
}

const initialState: IFilesReducer = {
  files: [],
  filesDataUrl: [],
  dataFetch: false,
  error: "",
};

export const fileReducer = createSlice({
  name: "File",
  initialState: initialState,
  reducers: {
    load: (state) => {
      state.dataFetch = true;
    },
    success: (state) => {
      state.dataFetch = false;
    },
    loadUserFiles: (state, action) => {
      state.dataFetch = false;
      state.error = "";
      state.files = action.payload;
    },
    loadUserFilesDataUrl: (state, action) => {
      state.dataFetch = false;
      state.error = "";
      state.filesDataUrl.push(action.payload);
    },
    emptyUserFilesDataUrl: (state) => {
      state.dataFetch = false;
      state.error = "";
      state.files = [];
      state.filesDataUrl = [];
    },
  },
});

export const {
  load,
  success,
  loadUserFiles,
  loadUserFilesDataUrl,
  emptyUserFilesDataUrl,
} = fileReducer.actions;
