import styled from "styled-components";

interface IButtonNeumorphicProps {
  colorText?: string;
  gradient1?: string;
  gradient2?: string;
  fontWeight?: string;
}

const ButtonNeumorphicRoot = styled.button`
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  justify-content: center;
  position: relative;
  z-index: 1;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0.5rem;
  cursor: pointer;
  user-select: none;
  color: ${(props: IButtonNeumorphicProps) =>
    props.colorText || "rgb(36, 36, 36);"};
  font-weight: ${(props: IButtonNeumorphicProps) => props.fontWeight || ""};
  transition: all 150ms ease-in-out 0s;
  // box-shadow: rgb(12 22 44 / 30%) 0px 9px 20px 1px;

  box-sizing: border-box;
  outline: 0px;
  border: 0px;
  vertical-align: middle;
  appearance: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.02857em;
  display: inline-flex;
  /* font-size: 1rem;
  font-weight: 700; */
  border-radius: 0.5rem;
  line-height: 1.4;
  text-transform: uppercase;
  transition: all 150ms ease-in;
  min-height: 2.5rem;
  padding: 0.5rem;
  background: ${(props: IButtonNeumorphicProps) =>
    props.gradient1
      ? `linear-gradient(-65deg, ${props.gradient1} , transparent)`
      : "linear-gradient(-65deg, rgb(255 255 255 / 35%), transparent)"};
  box-shadow: 3px 3px 8px 0 rgb(0 0 0 / 15%), -3px -3px 8px 0 rgb(255 255 255);

  &:hover {
    box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 15%), -1px -1px 3px 0 rgb(255 255 255);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export default ButtonNeumorphicRoot;
