import { createSlice } from "@reduxjs/toolkit";
import readUserPosts from "../../utils/api/readUserPosts";
import { IPostData } from "../../utils/api/WritePost";

export interface IPostReducer {
  posts: Record<string, IPostData>;
  dataFetch: boolean;
  error: any;
}

const initialState: IPostReducer = {
  posts: {},
  dataFetch: false,
  error: undefined,
};

export const postsReducer = createSlice({
  name: "Posts",
  initialState: initialState,
  reducers: {
    load: (state) => {
      state.dataFetch = true;
    },
    success: (state) => {
      state.dataFetch = false;
    },
    loadUserPosts: (state, action) => {
      state.dataFetch = false;
      state.error = "";
      state.posts = action.payload;
    },
  },
});

export const { load, success, loadUserPosts } = postsReducer.actions;

export const loadUserPostsFromDB = (
  uid: string | null,
  friendsUid: string[]
) => {
  return async (dispatch: any) => {
    dispatch(load());
    try {
      await readUserPosts(uid, friendsUid).then((res) => {
        if (res) {
          dispatch(loadUserPosts(res));
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
};
