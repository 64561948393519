import React from "react";
import {
  DrawerArea,
  DrawerBox,
  DrawerHighlightBox,
  DrawerListRoutesBox,
  DrawerLogoBox,
  DrawerLogoImg,
  // DrawerLogoImg,
  DrawerLogoLink,
  DrawerRoute,
  DrawerRouteIcon,
  DrawerRouteName,
  IDrawer,
} from "./Drawer.styled";

import logo from "../../assets/images/hand-logo.webp";
import styled from "styled-components";
import { openDrawerMobile } from "../../store/applications/UIReducer";
import { HeaderDrawerTogglerTablet } from "../Header/Header.styled";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import { ButtonMQEA } from "../Hero/Hero";
import { loadAuthEmpty } from "../../store/applications/auth";
import { useTheme } from "../../context/ThemeContext";
import { Divider } from "../Divider/Divider";

export const ListLiElement = styled.li`
  color: #fff;
  display: flex;
`;

export function logout() {
  window.localStorage.removeItem("uid-mqea");
  window.localStorage.removeItem("auth-mqea");
  return signOut(auth);
}

function Drawer({ drawerDesktop, drawerMobile, routes }: IDrawer) {
  const dispatch = useAppDispatch();
  const UI = useAppSelector((state) => state.UIReducer);
  const authData = useAppSelector((state) => state.AuthReducer);
  const theme = useTheme();

  return (
    <DrawerArea>
      <DrawerBox
        drawerDesktop={drawerDesktop}
        drawerMobile={drawerMobile}
        routes={routes}
      >
        <HeaderDrawerTogglerTablet
          onClick={() => dispatch(openDrawerMobile(!UI.drawerMobile))}
        >
          <span style={{ color: "white" }} className="material-icons-outlined">
            {UI.drawerMobile ? "menu_open" : "menu"}
          </span>
        </HeaderDrawerTogglerTablet>
        <DrawerLogoBox>
          <DrawerLogoLink>
            <DrawerLogoImg src={logo} alt="MonQuartierEnAction logo" />
          </DrawerLogoLink>
        </DrawerLogoBox>
        <Divider />
        <DrawerListRoutesBox>
          {routes.map((r, index) => {
            return (
              <DrawerRoute href={r.route} key={index}>
                {/* <DrawerRouteIcon className="material-icons">
                  {r.icon}
                </DrawerRouteIcon> */}
                <DrawerRouteIcon src={r.icon} />
                <DrawerRouteName key={r.key}>{r.name}</DrawerRouteName>
              </DrawerRoute>
            );
          })}
        </DrawerListRoutesBox>
        <DrawerHighlightBox></DrawerHighlightBox>
        {authData.uid !== "" && (
          <ButtonMQEA
            theme={theme}
            onClick={() =>
              logout().then(() => {
                dispatch(loadAuthEmpty());
                window.location.reload();
              })
            }
          >
            Déconnexion
          </ButtonMQEA>
        )}
        <DrawerHighlightBox></DrawerHighlightBox>
      </DrawerBox>
    </DrawerArea>
  );
}

// {routes.map((r, index) => {
//   return (
//     <ListLiElement key={index}>
//       <span className="material-icons">{r.icon}</span>
//       <p>{r.name}</p>
//     </ListLiElement>
//   );
// })}

export default Drawer;
