import axios from "axios";

const readLogementById = async (numUnique: string) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const url = `${baseUrl}/logement/${numUnique}`;
  const response = await axios.get(url);
  return response.data;
};

export default readLogementById;
