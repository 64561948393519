import axios from "axios";

/**
 * Uploads the logement-related files to the specified endpoint, organized by category.
 * @param {Object} logementFiles An object containing arrays of files for each category.
 * @returns Promise resolved with the API response or rejected with an error.
 */
const writeLogementUploadFiles = async (
  numeroUnique: string,
  logementFiles: {
    livret: File[];
    identity: File[];
    tax: File[];
    salary: File[];
    other: File[];
  }
) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const url = `${baseUrl}/logement/upload-files/${numeroUnique}`;

  const formData = new FormData();
  Object.entries(logementFiles).forEach(([category, files]) => {
    files.forEach((file, index) => {
      formData.append(
        `${category}[]`,
        file,
        `${category}_${index}_${file.name}`
      );
    });
  });

  const response = await axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};

export default writeLogementUploadFiles;
