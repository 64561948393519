import React, { ReactNode } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import routes from "../../routes";
import Footer from "../UI/Footer/Footer";
import { openDrawerMobile } from "../../store/applications/UIReducer";
import Header from "../Header/Header";
import Drawer from "../Drawer/Drawer";
// import Header from "../Header/Header";

const Screen = styled.div`
  opacity: 1;
  background: transparent;
  // color: white;
  padding: 10px;
  position: relative;
  transition: margin-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    margin-right 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  @media screen and (min-width: 992px) {
    margin-left: ${(props: { drawerDesktop: boolean }) =>
      props.drawerDesktop ? "17.125rem !important" : "0rem"};
  }

  @media screen and (min-width: 768px) {
    // margin-left: 7.5rem;
  }
`;

export const AppBox = styled.div`
  margin-top: 5px;
  min-height: 80vh;
  opacity: 1;
  background: transparent;
`;

function BaseLayout({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch();
  // const drawerState = useAppSelector((state) => state.UIReducer);
  // const drawerStateDesktop = useAppSelector(
  //   (state) => state.UIReducer.drawerDesktop
  // );
  const drawerStateMobile = useAppSelector(
    (state) => state.UIReducer.drawerMobile
  );

  // useEffect(() => {}, [drawerStateDesktop]);

  const handleClick = () => {
    if (drawerStateMobile) {
      dispatch(openDrawerMobile(!drawerStateMobile));
    }
  };

  return (
    <div>
      <Drawer
        drawerDesktop={drawerStateMobile}
        drawerMobile={drawerStateMobile}
        routes={routes}
      />
      <Screen drawerDesktop={drawerStateMobile}>
        <Header />
        <AppBox onClick={handleClick}>{children}</AppBox>
        <Footer />
      </Screen>
    </div>
  );
}

export default BaseLayout;
