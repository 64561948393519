// chroma-js is a library for all kinds of color conversions and color scales.
import chroma from "chroma-js";
/**
  The pxToRem() function helps you to convert a px unit into a rem unit, 
 */

export function pxToRem(number: number, baseNumber = 16) {
  return `${number / baseNumber}rem`;
}

/**
    The hexToRgb() function helps you to change the hex color code to rgb
    using chroma-js library.
   */

export function hexToRgb(color: string) {
  return chroma(color).rgb().join(", ");
}

/**
    The rgba() function helps you to create a rgba color code, it uses the hexToRgb() function
    to convert the hex code into rgb for using it inside the rgba color format.
   */

export function rgba(color: string, opacity: number) {
  return `rgba(${hexToRgb(color)}, ${opacity})`;
}

/**
    The boxShadow() function helps you to create a box shadow for an element
   */

export function boxShadow(
  offset: number[] = [], // eslint-disable-line
  radius: number[] = [], // eslint-disable-line
  color: string,
  opacity: number,
  inset: string | number = ""
) {
  const [x, y] = offset;
  const [blur, spread] = radius;

  return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(
    spread
  )} ${rgba(color, opacity)}`;
}

export function removeDuplicates(arr: any[]) {
  return arr?.filter((item, index) => arr?.indexOf(item) === index);
}

export const transformText = (text: string) => {
  // Convertir le texte en minuscules
  const lowercaseText = text.toLowerCase();

  // Remplacer les espaces par des tirets
  const transformedText = lowercaseText.replace(/ /g, "-");

  return transformedText;
};

export const removeStringsArray = (array1: string[], array2: string[]) => {
  // Utiliser la méthode filter pour créer un nouveau tableau qui ne contient que les éléments de array1 qui ne sont pas dans array2
  const filteredArray = array1.filter((str) => !array2.includes(str));

  return filteredArray;
};

export function generateRandomString(length: number) {
  return Math.random()
    .toString(36)
    .substring(2, 2 + length);
}
