import styled from "styled-components";

export const MQEA = styled.b`
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  background-image: linear-gradient(-45deg, #bb3e03, #e9d8a6, #ee9b00, #f72585);
  will-change: background, opacity, filter;
  background-size: 600%;
  animation: _text-animation 16s linear infinite;

  @keyframes _text-animation {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;
