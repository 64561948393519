import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ILogement } from "../../store/applications/logementReducer";
import readLogementAll from "../../utils/api/readLogementAll";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { loadAuth, loadAuthToken } from "../../store/applications/auth";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import writeLogementUpdateStatus from "../../utils/api/writeLogementUpdateStatus";

/**
 * Converts a timestamp to a formatted date string in dd/mm/yyyy format.
 * @param timestamp - The timestamp to convert.
 * @returns The formatted date string.
 */
export function convertTimestampToDate(timestamp: number): string {
  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

const getStatusColor = (status: string) => {
  switch (status) {
    case "En attente des documents":
      return "gray";
    case "En cours de traitement":
      return "darkgray";
    case "Demande envoyée":
      return "#E9D66B";
    case "En attente":
      return "#FFBF00";
    case "Passage CAL":
      return "#00BFFF";
    case "Attribué":
      return "#74C365";
    case "Refusé":
      return "#FF7F7F";
    default:
      return "black"; // Default color if status is unrecognized
  }
};

const statusOptions = [
  { label: "En attente des documents", color: "gray" },
  { label: "En cours de traitement", color: "darkgray" },
  { label: "Demande envoyée", color: "#E9D66B" },
  { label: "En attente", color: "#FFBF00" },
  { label: "Passage CAL", color: "#00BFFF" },
  { label: "Attribué", color: "#74C365" },
  { label: "Refusé", color: "#FF7F7F" },
];

const TableContainer = styled.div`
  overflow-x: auto; // Allows table to scroll horizontally on small screens
  margin-top: 20px;
  -webkit-overflow-scrolling: touch; // Smooth scrolling on iOS devices
  background: #e0e0e0;
  box-shadow: 5px 5px 10px #a3a3a3, -5px -5px 10px #ffffff;
`;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: #e0e0e0;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #a3a3a3, -5px -5px 10px #ffffff;

  th,
  td {
    padding: 10px 20px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #010b13;
    color: white;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  @media screen and (max-width: 768px) {
    th,
    td {
      padding: 10px 5px; // Reduce padding on smaller screens
    }
  }
`;

const Th = styled.th`
  cursor: pointer;
`;

// Container for the whole component
const Container = styled.div`
  margin: 1rem;
  padding: 20px;
  background: #e0e0e0;
  border-radius: 20px;
  box-shadow: 9px 9px 16px rgba(189, 189, 189, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.5);
`;

// Search bar styling
const SearchBar = styled.input`
  padding: 10px;
  border: none;
  border-radius: 20px;
  outline: none;
  box-shadow: inset 6px 6px 8px #cbced1, inset -6px -6px 8px #ffffff;
  width: 80%;
  margin-bottom: 20px;
`;

// Pagination controls styling
const PaginationControl = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;

  button {
    padding: 10px 15px;
    margin-left: 10px;
    border: none;
    border-radius: 20px;
    background: #e0e0e0;
    box-shadow: 5px 5px 10px #a3a3a3, -5px -5px 10px #ffffff;
    cursor: pointer;
    font-weight: bold;

    &:hover {
      background-color: #d1d1d1;
    }

    &:active {
      box-shadow: inset 1px 1px 2px #a3a3a3, inset -1px -1px 2px #ffffff;
    }
  }

  select {
    padding: 5px 10px;
    border-radius: 10px;
    border: none;
    margin-left: 10px;
    box-shadow: inset 2px 2px 5px #cbced1, inset -2px -2px 5px #ffffff;
    outline: none;
  }
`;

const DropdownButton = styled.button`
  background: white;
  // ${(props: { status: string }) => getStatusColor(props.status)};
  // color: white; // Assuming white text color for better readability on colored backgrounds
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-right: 5px;
  cursor: pointer;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  text-align: left;
  min-width: 150px;

  @media screen and (max-width: 682px) {
    min-width: 125px;
  }
`;

const StatusCircleButton = styled.span`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  background-color: ${(props: { status: string }) =>
    getStatusColor(props.status)};
`;

const DropdownMenu = styled.ul`
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 0;
  margin: 0;
  z-index: 1;
`;

const DropdownItem = styled.li`
  list-style: none;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #f6f6f6;
  }
`;

const StatusCircle = styled.span`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  background-color: ${(props) => props.color};
`;

function LogementList() {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const authData = useAppSelector((state) => state.AuthReducer);
  // eslint-disable-next-line
  const [isAuth, setIsAuth] = useState(
    false || window.localStorage.getItem("auth-mqea") === "true"
  );
  const [token, setToken] = useState("");
  // State for logement data
  const [logements, setLogements] = useState<ILogement[]>([]);

  // State for search term
  const [searchTerm, setSearchTerm] = useState("");

  // States for sorting
  const [sortKey, setSortKey] = useState(""); // Key to sort by
  const [sortOrder, setSortOrder] = useState("asc"); // 'asc' or 'desc'

  // States for pagination
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [itemsPerPage, setItemsPerPage] = useState(10); // Number of items per page

  const [showDropdown, setShowDropdown] = useState<Record<number, boolean>>({});
  const dropdownRefs = useRef<Record<number, HTMLUListElement | null>>({});

  const toggleDropdown = (index: number) => {
    setShowDropdown((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleStatusChange = (
    logementId: string,
    newStatus: string,
    index: number
  ) => {
    writeLogementUpdateStatus(logementId, newStatus); // Replace with actual API call logic
    setLogements(
      logements.map((logement) =>
        logement.numeroUnique === logementId
          ? { ...logement, statutDeLaDemande: newStatus }
          : logement
      )
    );
    setShowDropdown((prev) => ({ ...prev, [index]: false }));
  };

  // Handle clicking outside of dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      Object.entries(dropdownRefs.current).forEach(([index, ref]) => {
        if (ref && !ref.contains(target)) {
          setShowDropdown((prev) => ({ ...prev, [index]: false }));
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(loadAuth());
    if (isAuth) {
      onAuthStateChanged(auth, (userCred) => {
        userCred?.getIdToken().then((token) => {
          dispatch(loadAuthToken(token));
          setToken(token);
        });
      });
    } else {
      navigate("/connexion");
    }
  }, [token, authData.quartier]); // eslint-disable-line

  useEffect(() => {
    const fetchLogementData = async () => {
      try {
        const data = await readLogementAll();
        console.log(Object.values(data));
        setLogements(Object.values(data));
      } catch (error) {
        console.error("Error fetching logement data:", error);
        // Optionally handle errors, e.g., set error state, show error message, etc.
      }
    };

    fetchLogementData();
  }, []);

  // Functions for sorting
  const sortData = (key: keyof ILogement) => {
    const order = key === sortKey && sortOrder === "asc" ? "desc" : "asc";
    setSortKey(key);
    setSortOrder(order);

    const sortedLogements = [...logements].sort((a, b) => {
      if (a[key] < b[key]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });

    setLogements(sortedLogements);
  };

  // Function for searching
  const filterData = () => {
    return logements.filter((logement) =>
      Object.values(logement).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  // Functions for pagination
  const paginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filterData().slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil(filterData().length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setItemsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <Container>
      <SearchBar
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <TableContainer>
        <ResponsiveTable>
          <thead>
            <tr>
              <Th onClick={() => sortData("statutDeLaDemande")}>Statut</Th>
              <Th onClick={() => sortData("numeroUnique")}>Numéro Unique</Th>
              <Th onClick={() => sortData("prenom")}>Prénom</Th>
              <Th onClick={() => sortData("nom")}>Nom</Th>
              <Th onClick={() => sortData("email")}>Email/Tel</Th>
              <Th onClick={() => sortData("typeDeLogementActuel")}>
                Logement actuel
              </Th>
              <Th onClick={() => sortData("typeDeLogementSouhaite")}>
                Logement demandé
              </Th>
              <Th onClick={() => sortData("bailleurActuel")}>
                Bailleur actuel
              </Th>
              <Th onClick={() => sortData("dateDEnregistrement")}>Date</Th>

              {/* Add more headers as per ILogement properties */}
            </tr>
          </thead>
          <tbody>
            {paginatedData().map((logement, index) => (
              <tr key={index}>
                <td>
                  <DropdownButton
                    onClick={() => toggleDropdown(index)}
                    status={logement.statutDeLaDemande}
                  >
                    <StatusCircleButton status={logement.statutDeLaDemande} />
                    {logement.statutDeLaDemande}
                  </DropdownButton>
                  {showDropdown[index] && (
                    <DropdownMenu>
                      {statusOptions.map((statusOption) => (
                        <DropdownItem
                          key={statusOption.label}
                          onClick={() =>
                            handleStatusChange(
                              logement.numeroUnique,
                              statusOption.label,
                              index
                            )
                          }
                        >
                          <StatusCircle color={statusOption.color} />
                          {statusOption.label}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  )}
                </td>
                <td>
                  <a
                    href={`/logement/${logement.numeroUnique}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {logement.numeroUnique}
                  </a>
                </td>
                <td>{logement.prenom}</td>
                <td>{logement.nom}</td>
                <td>
                  {logement.email}
                  <br />
                  {logement.numeroDeTelephone}
                </td>
                <td>{logement.typeDeLogementActuel.toUpperCase()}</td>
                <td>{logement.typeDeLogementSouhaite.toUpperCase()}</td>
                <td>{logement.bailleurActuel}</td>
                <td>
                  {convertTimestampToDate(Number(logement.dateDEnregistrement))}
                </td>

                {/* Add more data cells as per ILogement properties */}
              </tr>
            ))}
          </tbody>
        </ResponsiveTable>
      </TableContainer>

      <PaginationControl>
        <button onClick={goToPreviousPage} disabled={currentPage === 1}>
          Prev
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={goToNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
        <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </PaginationControl>
    </Container>
  );
}

export default LogementList;
