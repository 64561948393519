export interface Palette {
  background: string;
  headline: string;
  paragraph: string;
  button: string;
  buttonText: string;
  tertiary?: string;
  stroke?: string;
  placeholder?: string;
}

export const palette = {
  primary: {
    background: "#fcf9f5",
    backgroundMenu: "#fcf9f5",
    headline: "#3c3c41",
    paragraph: "#3c3c41",
    paragraphLight: "#3c3c41",
    button: "#45C0DC", // "#45C0DC",
    button2: "#2188A1", // "#45C0DC",
    buttonHover: "#32CD32",
    buttonText: "#fff",
    tertiary: "#e16162",
    stroke: "#001e1d",
    placeholder: "#001e1d7b",
  },
  secondary: {
    background: "#004643",
    headline: "#fffffe",
    paragraph: "#fffffe",
    button: "#f9bc60",
    buttonText: "#001e1d",
    tertiary: "#e16162",
  },
};
