import styled from "styled-components";

export const ProfileBox = styled.div`
  padding: 24px 15px 8px;
  text-align: center;
  opacity: 1;
  background: transparent;
  color: white;
`;

export const ProfileCardBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  border-radius: 1rem;
  min-height: 2.5rem;
  padding: 10px;
  box-shadow: rgb(0 0 0 / 11%) 0rem 0.25rem 0.4375rem -0.0625rem,
    rgb(0 0 0 / 7%) 0rem 0.125rem 0.25rem -0.0625rem;
  background-image: linear-gradient(310deg, rgb(20, 23, 39), rgb(58, 65, 111));
`;

export const ProfilePersonalData = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const ProfilePicture = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
`;

export const ProfileName = styled.span`
  font-weight: 600;
  margin: 0;
`;

export const ProfileFollowers = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
`;

export const ProfileFollowersData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProfileFollowersDataName = styled.p`
  font-weight: 200;
  margin: 0.2rem 0.1rem;
`;
export const ProfileFollowersDataCount = styled.p`
  font-weight: 600;
  margin: 0.2rem 0.1rem;
`;
