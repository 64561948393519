import React, { ChangeEvent, useState } from "react";
import {
  FlexColCenter,
  FlexColStart,
  FlexRowCenter,
} from "../../components/UI/Flex/Flex";
import {
  ButtonMQEA,
  ContentWrapper,
  HeroContainer,
  Logo,
  StyledLink,
  Subtitle,
  Title,
} from "../../components/Hero/Hero";
import { Form, InputNeumorphic } from "../../components/UI/Forms/Forms.styled";
import { useTheme } from "../../context/ThemeContext";
import logo from "../../assets/images/travaux.webp";
import ButtonNeumorphicRoot from "../../components/UI/ButtonNeumorphic/ButtonNeumorphic.styled";
import { useNavigate } from "react-router-dom";
import { Divider } from "../../components/Divider/Divider";

function Technique() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [numeroUnique, setNumeroUnique] = useState("");

  const handleInput = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    setNumeroUnique(e.target.value);
  };

  return (
    <div>
      <ButtonNeumorphicRoot onClick={() => navigate(-1)}>
        <span className="material-icons" style={{ fontSize: "18px" }}>
          arrow_back
        </span>
      </ButtonNeumorphicRoot>

      <HeroContainer theme={theme}>
        <ContentWrapper>
          <Logo src={logo} alt="monquartierenaction logo" />
          <Title theme={theme}>
            Signalez un dysfonctionnement ou une anomalie.
          </Title>
          <Subtitle theme={theme}>
            (1) Veuillez remplir le formulaire de signalement. (2) Nous allons
            traiter votre demande et (3) solliciter les services compétents pour
            résoudre la problématique.
          </Subtitle>
          <FlexRowCenter>
            <FlexColCenter>
              <StyledLink to="/technique/nouveau" theme={theme}>
                Nouveau signalement
              </StyledLink>
              <Divider />
              <Form action={`/technique/${numeroUnique}`}>
                <InputNeumorphic
                  required
                  placeholder="Numéro identifiant du signalement"
                  onChange={handleInput}
                />
                <ButtonMQEA type="submit" theme={theme}>
                  Suivre un signalement
                </ButtonMQEA>
              </Form>
              <FlexColStart></FlexColStart>
            </FlexColCenter>
          </FlexRowCenter>
        </ContentWrapper>
      </HeroContainer>
    </div>
  );
}

export default Technique;
