import React from "react";
import Login from "./pages/Login/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LogementAdmin from "./pages/Logement/LogementAdmin";
import BaseLayout from "./components/BaseLayout/BaseLayout";
// import Fil from "./pages/Fil/Fil";
import LogementDetail from "./pages/Logement/LogementDetails";
import LogementList from "./pages/Logement/LogementList";
import Homepage from "./pages/Homepage/Homepage";
import { ThemeProvider } from "./context/ThemeContext";
import Logement from "./pages/Logement/Logement";
import Technique from "./pages/Technique/Technique";
import TechniqueAdd from "./pages/Technique/TechniqueAdd";
import TechniqueDetails from "./pages/Technique/TechniqueDetails";
import TechniqueList from "./pages/Technique/TechniqueList";
import Logout from "./components/Logout/Logout";
import Profile from "./pages/Profile/Profile";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <BaseLayout>
          <Routes>
            <Route path="/" element={<Homepage />}></Route>
            {/* <Route path="/fil" element={<Fil />}></Route> */}
            {/* <Route path="/invite/:uid" element={<Login />}></Route> */}
            {/* Logement */}
            <Route path="/logement" element={<Logement />} />
            <Route path="/logement/nouveau" element={<LogementAdmin />} />
            <Route
              path="/logement/:numeroUnique"
              element={<LogementDetail />}
            />
            <Route path="/logement/list" element={<LogementList />} />
            {/* Technique */}
            <Route path="/technique" element={<Technique />} />
            <Route path="/technique/nouveau" element={<TechniqueAdd />} />
            <Route
              path="/technique/:numeroUnique"
              element={<TechniqueDetails />}
            />
            <Route path="/technique/list" element={<TechniqueList />} />
            {/* USER */}
            <Route path="/connexion" element={<Login />}></Route>
            <Route path="/deconnexion" element={<Logout />}></Route>
            <Route path="/profile" element={<Profile />}></Route>
          </Routes>
        </BaseLayout>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
