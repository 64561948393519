import { createSlice } from "@reduxjs/toolkit";
import { removeStringsArray } from "../../utils";
import { readFollow } from "../../utils/api/readfriends";
import { readListHabitantsQuartier } from "../../utils/api/readHabitants";
import { readUserPublicData } from "../../utils/api/readUserDataOld";
import { IUserPublicData } from "../../utils/api/writeNewUser";

export interface IAmisReducer {
  inviter: IUserPublicData;
  habitantQuartier: IUserPublicData[];
  habitantVille: IUserPublicData[];
  followers: IUserPublicData[];
  following: IUserPublicData[];
  dataFetch: boolean;
  error: any;
}

const initialState: IAmisReducer = {
  inviter: {
    uid: "",
    pic: "https://firebasestorage.googleapis.com/v0/b/monquartierenaction.appspot.com/o/user-pic.webp?alt=media&token=3c1ce4f2-d2fb-4f34-9179-f1b0d9b364df",
    nom: "",
    prenom: "",
    gender: "",
    pays: "",
    ville: "",
    quartier: "",
    voisins: [],
    posts: [],
    events: [],
    projects: [],
    emailVerified: false,
  },
  habitantQuartier: [],
  habitantVille: [],
  followers: [],
  following: [],
  dataFetch: false,
  error: undefined,
};

export const amisReducer = createSlice({
  name: "Amis",
  initialState: initialState,
  reducers: {
    load: (state) => {
      state.dataFetch = true;
    },
    success: (state) => {
      state.dataFetch = false;
    },
    loadInviterData: (state, action) => {
      state.dataFetch = false;
      state.error = "";
      state.inviter = action.payload;
    },
    loadHabitantsQuartierData: (state, action) => {
      state.dataFetch = false;
      state.error = "";
      state.habitantQuartier = action.payload;
    },
    loadHabitantsVilleData: (state, action) => {
      state.dataFetch = false;
      state.error = "";
      state.habitantVille = action.payload;
    },
    loadFollowersData: (state, action) => {
      state.dataFetch = false;
      state.error = "";
      state.followers = action.payload;
    },
    loadFollowingData: (state, action) => {
      state.dataFetch = false;
      state.error = "";
      state.following = action.payload;
    },
  },
});

export const {
  load,
  success,
  loadInviterData,
  loadHabitantsQuartierData,
  loadHabitantsVilleData,
  loadFollowersData,
  loadFollowingData,
} = amisReducer.actions;

export const loadInviterDataFromDB = (uid: string | null) => {
  return async (dispatch: any) => {
    dispatch(load());
    try {
      await readUserPublicData(uid).then((res) => {
        if (res) {
          dispatch(loadInviterData(res));
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const loadFollowDataFromDB = (uid: string | null) => {
  return async (dispatch: any) => {
    dispatch(load());
    let listFollowers: IUserPublicData[] = [];
    let listFollowing: IUserPublicData[] = [];
    try {
      const follow = await readFollow(uid);
      for (let i = 0; i < follow.followers.length; i++) {
        const response = await readUserPublicData(follow.followers[i]);
        listFollowers.push(response);
      }
      for (let i = 0; i < follow.following.length; i++) {
        const response = await readUserPublicData(follow.following[i]);
        listFollowing.push(response);
      }
      dispatch(loadFollowingData(listFollowing));
      dispatch(loadFollowersData(listFollowers));
    } catch (error) {
      console.log(error);
    }
  };
};

export const loadHabitantQuartierDataFromDB = (
  quartier: string,
  uid: string | null,
  amis: string[]
) => {
  return async (dispatch: any) => {
    dispatch(load());
    let listOfHabitants: IUserPublicData[] = [];
    try {
      await readListHabitantsQuartier(quartier).then(async (res) => {
        if (res) {
          let toFilter: string[] = [];
          if (amis) {
            amis.forEach((ami) => {
              toFilter.push(ami);
            });
          }
          if (uid) toFilter.push(uid);
          const listClean = removeStringsArray(res, toFilter);
          const follow = await readFollow(uid);
          // let friendsRequest: string [] = []
          // await readFriendsRequestSent(uid).then((res)=>{
          //   if(res){
          //     for(let i=0; i < res.length)
          //   }
          // });

          const listCleaned = removeStringsArray(listClean, follow.following);
          if (listCleaned.length > 5) {
            for (let i = 0; i < 5; i++) {
              const response = await readUserPublicData(listCleaned[i]);
              listOfHabitants.push(response);
            }
          } else {
            for (let i = 0; i < listCleaned.length; i++) {
              const response = await readUserPublicData(listCleaned[i]);
              listOfHabitants.push(response);
            }
          }
          dispatch(loadHabitantsQuartierData(listOfHabitants));
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
};
