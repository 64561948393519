import { createSlice } from "@reduxjs/toolkit";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import readUserData from "../../utils/api/readUserData";

export interface IUserData {
  uid: string;
  token: string;
  email: string;
  emailVerified: boolean;
  isAnonymous: boolean;
  userType: string;
  dateDEnregistrement: number;
  pic: string;
  nom: string;
  prenom: string;
  sexe: string;
  dateDeNaissance: string;
  ville: string;
  quartier: string;
  adresse: string;
  adresseComplement: string;
  numeroDeTelephone: string;
  salaireBrut: number;
  salaireAvisImpot1: number;
  salaireAvisImpot2: number;
  situationFamiliale: string; // Family situation ok
  nombreDEnfants: number; // Number of children ok
  logementActuel: string; // Current housing ok
  typeDeLogementActuel: string; // Type of current housing ok
  bailleurActuel: string; // Current landlord ok
}

export const initialUserData = {
  uid: "",
  token: "",
  email: "",
  emailVerified: false,
  isAnonymous: false,
  userType: "habitant",
  dateDEnregistrement: Date.now(),
  pic: "https://firebasestorage.googleapis.com/v0/b/monquartierenaction.appspot.com/o/user-pic.webp?alt=media&token=3c1ce4f2-d2fb-4f34-9179-f1b0d9b364df",
  nom: "",
  prenom: "",
  sexe: "",
  dateDeNaissance: "",
  ville: "",
  quartier: "",
  adresse: "",
  adresseComplement: "",
  numeroDeTelephone: "",
  salaireBrut: 0,
  salaireAvisImpot1: 0,
  salaireAvisImpot2: 0,
  situationFamiliale: "", // Family situation ok
  nombreDEnfants: 0, // Number of children ok
  logementActuel: "", // Current housing ok
  typeDeLogementActuel: "", // Type of current housing ok
  bailleurActuel: "", // Current landlord ok
};

const authInitialState = {
  uid: "",
  token: "",
  email: "",
  emailVerified: false,
  isAnonymous: false,
  userType: "habitant",
  dateDEnregistrement: Date.now(),
  pic: "https://firebasestorage.googleapis.com/v0/b/monquartierenaction.appspot.com/o/user-pic.webp?alt=media&token=3c1ce4f2-d2fb-4f34-9179-f1b0d9b364df",
  nom: "",
  prenom: "",
  sexe: "",
  dateDeNaissance: "",
  ville: "",
  quartier: "",
  adresse: "",
  adresseComplement: "",
  numeroDeTelephone: "",
  salaireBrut: 0,
  salaireAvisImpot1: 0,
  salaireAvisImpot2: 0,
  situationFamiliale: "", // Family situation ok
  nombreDEnfants: 0, // Number of children ok
  logementActuel: "", // Current housing ok
  typeDeLogementActuel: "", // Type of current housing ok
  bailleurActuel: "", // Current landlord ok
  fetch: false,
  error: "",
};

export const authReducer = createSlice({
  name: "Auth",
  initialState: authInitialState,
  reducers: {
    loadAuthFetch: (state) => {
      state.fetch = true;
      state.error = "";
    },
    loadAuthData: (state, action) => {
      state.fetch = false;
      state.error = "";
      state.email = action.payload.email;
      state.nom = action.payload.nom;
      state.prenom = action.payload.prenom;
      state.pic = action.payload.pic;
      state.sexe = action.payload.sexe;
      state.dateDeNaissance = action.payload.dateDeNaissance;
      state.quartier = action.payload.quartierActuel;
      state.adresse = action.payload.adresse;
      state.adresseComplement = action.payload.adresseComplement;
      state.emailVerified = action.payload.emailVerified;
      state.userType = action.payload.userType;
      state.dateDEnregistrement = action.payload.dateDEnregistrement;
      state.uid = action.payload.uid;
      state.ville = action.payload.ville;
      state.numeroDeTelephone = action.payload.numeroDeTelephone;
      state.salaireBrut = action.payload.salaireBrut;
      state.salaireAvisImpot1 = action.payload.salaireAvisImpot1;
      state.salaireAvisImpot2 = action.payload.salaireAvisImpot2;
      state.situationFamiliale = action.payload.situationFamiliale; // Family situation ok
      state.nombreDEnfants = action.payload.nombreDEnfants; // Number of children ok
      state.logementActuel = action.payload.logementActuel; // Current housing ok
      state.typeDeLogementActuel = action.payload.typeDeLogementActuel; // Type of current housing ok
      state.bailleurActuel = action.payload.bailleurActuel; // Current landlord ok
    },
    loadAuthToken: (state, action) => {
      state.fetch = false;
      state.error = "";
      state.token = action.payload;
    },
    loadAuthUid: (state, action) => {
      state.fetch = false;
      state.error = "";
      state.uid = action.payload;
    },
    loadAuthError: (state, action) => {
      state.fetch = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadAuthFetch,
  loadAuthData,
  loadAuthToken,
  loadAuthUid,
  loadAuthError,
} = authReducer.actions;

export const loadAuth = () => {
  return async (dispatch: any) => {
    dispatch(loadAuthFetch());
    try {
      onAuthStateChanged(auth, (userCred) => {
        userCred?.getIdToken().then(async (tok) => {
          window.localStorage.setItem("auth-mqea", "true");
          window.localStorage.setItem("uid-mqea", userCred.uid);
          dispatch(loadAuthToken(tok));
          dispatch(loadAuthUid(userCred.uid));
          await dispatch(loadAuthUserData(userCred.uid));
          // dispatch(loadAuthData(userCred));
        });
      });
    } catch (error) {
      dispatch(loadAuthError(error));
    }
  };
};

export const loadAuthUserData = (uid: string | null) => {
  return async (dispatch: any) => {
    dispatch(loadAuthFetch());
    if (uid) {
      try {
        await readUserData(uid).then((res) => {
          dispatch(loadAuthData(res));
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
};

export const loadAuthEmpty = () => {
  return async (dispatch: any) => {
    dispatch(loadAuthData(authInitialState));
    dispatch(loadAuthUid(""));
    dispatch(loadAuthToken(""));
  };
};
