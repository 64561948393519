import React from "react";
import Hero from "../../components/Hero/Hero";
import styled from "styled-components";

export const SwapDemoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

function Homepage() {
  return (
    <div>
      <Hero />
    </div>
  );
}

export default Homepage;
