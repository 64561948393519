import axios from "axios";
import { ILogement } from "../../store/applications/logementReducer";

/**
 * Submits the housing request form data to the server.
 * @param {ILogement} formData The housing request form data.
 * @returns Promise resolved with the API response or rejected with an error.
 */
const writeLogementNew = async (formData: ILogement) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const url = `${baseUrl}/logement/add`;

  try {
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data; // You can adjust this return based on how your API responds
  } catch (error) {
    console.log("Error submitting housing request:", error);
    throw error; // You may still want to throw the error to handle it outside this function
  }
};

export default writeLogementNew;
