import React, { useEffect, useState } from "react";

//import curved from "../../assets/images/curved-images/curved0.jpg";
import logoHand from "../../assets/images/hand-logo.webp";
import inviteHand from "../../assets/images/hand-event.webp";

import google from "../../assets/images/google.png";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "../../firebase/firebase";
import {
  LoginAction,
  LoginActionBox,
  LoginBox,
  LoginButton,
  LoginLogo,
  LoginPart1Box,
} from "./Login.styled";
import { MQEA } from "../../components/UI/Text/mqea/mqea.styled";
import {
  FormBox,
  InlineInputBox,
  InputNeumorphic,
  Label,
  SelectBox,
  SelectLab,
  StyledOption,
} from "../../components/UI/Forms/Forms.styled";
import {
  Modal,
  ModalBox,
  ModalLayer,
} from "../../components/UI/Modal/Modal.styled";
import { writeNewUser, IUserData } from "../../utils/api/writeNewUser";
import { PageSubTitle, TextBold } from "../../components/UI/Text/Text.styled";
import Divider from "../../components/UI/Divider/Divider";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { loadInviterDataFromDB } from "../../store/applications/AmisReducer";
import { ProfilePicture } from "../../components/Features/ProfileCard/ProfileCard.styled";
import ButtonNeumorphicRoot from "../../components/UI/ButtonNeumorphic/ButtonNeumorphic.styled";

const rues = [
  "All. des Amandiers",
  "All. d'Estienne d'Orves",
  "All. des Marguerites",

  "Av. de Bagatelle",
  "Av. du Camp de Menthe",
  "Av. du Deffens",
  "Av. de l'Europe",
  "Av. du Four d'Eyglun",
  "Av. des Jardins d'Estelle",
  "Av. Jules Payot",
  "Av. Marcel Pagnol",
  "Av. Pablo Picasso",
  "Av. Saint-John Perse",
  "Av. des Siffleuses",

  "Bd du Clos Gabriel",
  "Bd du Coq d'Argent",
  "Bd des 2 Ormes",
  "Bd de la Grande Thumine",
  "Bd des Vignes de Marius",

  "Ch. des Aubépines",
  "Ch. Bruno Durand",
  "Ch. de la Croix Verte",
  "Ch. de la Doudonne",
  "Ch. de l'Espero",
  "Ch. des Flaneurs",
  "Ch. Giuseppe Varacalli",
  "Ch. des Piboules",
  "Ch. du Pilon du Roy",
  "Ch. des Saints-Pères",
  "Ch. de la Souque",
  "Ch. des 3 Cyprès",
  "Ch. de la Vierge Noire",

  "Cours Bruno Durand",

  "Impasse des Frères Pratesi",
  "Impasse de la Garenne",

  "Pl. Albert Laforest",
  "Pl. de la Croix Verte",
  "Pl. Victor Schoelcher",

  "Rotonde du Bois de l'Aune",

  "Rte de Berre",
  "Rte de Galice",
  "Rte de Valcros",

  "Rue Achille Emperaire",
  "Rue André Chénier",
  "Rue Charloun Rieu",
  "Rue Château de l'Horloge",
  "Rue Edmond Jaloux",
  "Rue Hugo Ely",
  "Rue Joseph Diouloufet",
  "Rue Léon d'Astros",
  "Rue Maurice Aicardi-Lejard",
  "Rue Pascal du Verger",
  "Rue Paul Sabatier",
  "Rue du Piémont",
  "Rue Raoul Follereau",
  "Rue de la Tramontane",
  "Rue de la Verdière",

  "Square Agora",
];

function Login() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const amisStore = useAppSelector((state) => state.AmisReducer);
  const params = useParams();
  const [isAuth, setIsAuth] = useState(
    false || window.localStorage.getItem("auth-mqea") === "true"
  );
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [birthday, setBirthday] = useState(
    new Date().toISOString().split("T")[0]
  );
  const genders = ["F", "H", "Autre"];
  const quartiers = ["Jas de Bouffan"];
  const villes = ["Aix-en-Provence"];
  const [gender, setGender] = useState(genders[0]);
  const [ville, setVille] = useState(villes[0]);
  const [quartier, setQuartier] = useState(quartiers[0]);
  const [residence, setResidence] = useState("");
  const [rue, setRue] = useState(rues[0]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [displayModal, setDisplayModal] = useState(false);

  let userData: IUserData = {
    uid: "",
    pic: "",
    nom: "",
    prenom: "",
    gender: "",
    birthday: "",
    pays: "France",
    ville: "",
    quartier: "",
    residence: "",
    rue: "",
    email: "",
    emailVerified: false,
    voisins: [],
    posts: [],
    events: [],
    projects: [],
  };

  const provider = new GoogleAuthProvider();
  // const auth = getAuth();
  const loginWithGoogle = () => {
    signInWithPopup(auth, provider).then((userCred) => {
      console.log(userCred.user);
      window.localStorage.setItem("uid-mqea", userCred.user.uid);
      window.localStorage.setItem("auth-mqea", "true");
      setIsAuth(true);
      const _nom = userCred.user.displayName?.split(" ")[0];
      const _prenom = userCred.user.displayName?.split(" ")[1];
      try {
        userData.uid = userCred.user.uid;
        userData.pic = userCred.user.photoURL ? userCred.user.photoURL : "";
        userData.nom = typeof _nom === "undefined" ? "" : _nom;
        userData.prenom = typeof _prenom === "undefined" ? "" : _prenom;
        userData.gender = "";
        userData.quartier = "";
        userData.residence = "";
        userData.rue = "";
        userData.email = userCred.user.email ? userCred.user.email : "";
        userData.birthday = "";
        console.log(userData);
        writeNewUser(userData, userCred.user.uid);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const createWithEmail = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCred) => {
        window.localStorage.setItem("uid-mqea", userCred.user.uid);
        window.localStorage.setItem("auth-mqea", "true");
        setIsAuth(true);
        try {
          userData.uid = userCred.user.uid;
          userData.pic = `https://firebasestorage.googleapis.com/v0/b/monquartierenaction.appspot.com/o/user-pic.webp?alt=media&token=3c1ce4f2-d2fb-4f34-9179-f1b0d9b364df`;
          userData.nom = nom
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          userData.prenom = prenom
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          userData.gender = gender;
          userData.ville = ville;
          userData.quartier = quartier;
          userData.residence = residence;
          userData.rue = rue;
          userData.email = email;
          userData.birthday = birthday;
          // params.uid && userData.amis.push(params.uid);
          writeNewUser(userData, userCred.user.uid, params.uid);
        } catch (error) {
          console.log(error);
        }
      })
      .catch((e: { code: string; message: string }) => {
        console.log(e.code === "auth/email-already-in-use");
      });
  };

  const signinWithEmail = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCred) => {
        window.localStorage.setItem("uid-mqea", userCred.user.uid);
        window.localStorage.setItem("auth-mqea", "true");
        setIsAuth(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    console.log("params");
    console.log(params.uid);
    if (params.uid) {
      dispatch(loadInviterDataFromDB(params.uid))
        .then(() => {
          console.log("inviter data loaded");
        })
        .catch((e) => console.log(e));
    }
    if (isAuth) {
      navigate("/logement");
    }
  }, [isAuth]); // eslint-disable-line

  return (
    <LoginBox>
      <LoginPart1Box>
        <LoginLogo src={logoHand} />
        <LoginActionBox>
          {params.uid && (
            <PageSubTitle>
              Invitation reçue par{" "}
              <MQEA>
                {amisStore.inviter.prenom} {amisStore.inviter.nom}
              </MQEA>
              <ProfilePicture src={inviteHand} />
            </PageSubTitle>
          )}
          <h1>Bonjour 👋</h1>
          <TextBold>
            <MQEA>MonQuartierEnAction</MQEA> vous permet de vous connecter avec
            d'autres habitants de votre quartier et de partager vos idées.
          </TextBold>
          <p style={{ color: "#444" }}>
            Grâce à <MQEA>MonQuartierEnAction</MQEA> exprimez votre citoyenneté
            pour un meilleur quartier, plus vivant, plus agréable et plus animé.
          </p>
        </LoginActionBox>
      </LoginPart1Box>
      <LoginActionBox>
        <FormBox>
          <InputNeumorphic
            placeholder="Adresse e-mail"
            type="email"
            required
            onChange={(event) => setEmail(event.target.value)}
          />
          <InputNeumorphic
            placeholder="Mot de passe"
            type="password"
            required
            onChange={(event) => setPassword(event.target.value)}
          />
          <ButtonNeumorphicRoot
            gradient1="rgb(233, 216, 166)"
            fontWeight="600"
            onClick={signinWithEmail}
          >
            Se connecter
          </ButtonNeumorphicRoot>
          <Divider />
          <ButtonNeumorphicRoot
            // onClick={createWithEmail}
            onClick={() => setDisplayModal(!displayModal)}
            gradient1="#82f4b1"
            fontWeight="600"
            //gradient1="#6e78ff"
            //gradient2="#61f4de"
          >
            Créer nouveau compte
          </ButtonNeumorphicRoot>
        </FormBox>
        <p style={{ textAlign: "center", width: "100%" }}>OU</p>
        <LoginAction>
          <LoginButton onClick={loginWithGoogle}>
            <img
              alt="Google Logo"
              src={google}
              style={{ width: "1.2rem", verticalAlign: "sub" }}
            />{" "}
            Connexion avec Google
          </LoginButton>
        </LoginAction>
      </LoginActionBox>
      <ModalBox isDisplay={displayModal}>
        <ModalLayer onClick={() => setDisplayModal(!displayModal)}></ModalLayer>
        <Modal>
          <InlineInputBox>
            <InputNeumorphic
              placeholder="Prénom"
              type="text"
              required
              onChange={(event) => setPrenom(event.target.value)}
            />
            <InputNeumorphic
              placeholder="Nom de famille"
              type="text"
              required
              onChange={(event) => setNom(event.target.value)}
            />
          </InlineInputBox>
          <InputNeumorphic
            placeholder="Adresse e-mail"
            type="email"
            required
            onChange={(event) => setEmail(event.target.value)}
          />
          <InputNeumorphic
            placeholder="Nouveau mot de passe"
            type="password"
            required
            onChange={(event) => setPassword(event.target.value)}
          />
          <SelectBox>
            <Label>Ville:</Label>
            <SelectLab
              value={ville}
              name={ville}
              onChange={(event) => {
                setVille(event.target.selectedOptions[0].label);
              }}
            >
              {villes.map((ville, index) => (
                <StyledOption key={index} value={ville}>
                  {ville}
                </StyledOption>
              ))}
            </SelectLab>
          </SelectBox>
          <SelectBox>
            <Label>Quartier:</Label>
            <SelectLab
              value={quartier}
              name={quartier}
              onChange={(event) => {
                setQuartier(event.target.selectedOptions[0].label);
              }}
            >
              {quartiers.map((quartier, index) => (
                <StyledOption key={index} value={quartier}>
                  {quartier}
                </StyledOption>
              ))}
            </SelectLab>
          </SelectBox>
          <SelectBox>
            <Label>Rue:</Label>
            <SelectLab
              value={rue}
              name={rue}
              onChange={(event) => {
                setRue(event.target.selectedOptions[0].label);
              }}
            >
              {rues.map((rue, index) => (
                <StyledOption key={index} value={rue}>
                  {rue}
                </StyledOption>
              ))}
            </SelectLab>
          </SelectBox>
          <InputNeumorphic
            placeholder="Résidence"
            type="text"
            required
            onChange={(event) => setResidence(event.target.value)}
          />
          <SelectBox>
            <Label>Date de naissance:</Label>
            <InputNeumorphic
              placeholder="Date de naissance"
              type="date"
              value={birthday}
              required
              onChange={(event) => setBirthday(event.target.value)}
            />
          </SelectBox>
          <SelectBox>
            <Label>Genre:</Label>
            <SelectLab
              value={gender}
              name={gender}
              onChange={(event) => {
                console.log(event.target.selectedOptions[0].index);
                setGender(event.target.selectedOptions[0].label);
              }}
            >
              {genders.map((gender, index) => (
                <StyledOption key={index} value={gender}>
                  {gender}
                </StyledOption>
              ))}
            </SelectLab>
          </SelectBox>
          <ButtonNeumorphicRoot
            gradient1="#82f4b1"
            fontWeight="600"
            onClick={createWithEmail}
          >
            Créer nouveau compte
          </ButtonNeumorphicRoot>
        </Modal>
      </ModalBox>
    </LoginBox>
  );
}

export default Login;
