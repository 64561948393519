import React from "react";
import styled from "styled-components";

// Define the steps for the status tracking
const statusSteps = [
  "En attente des documents",
  "En cours de traitement",
  "Demande envoyée",
  "En attente",
  "Passage CAL",
  "Attribué",
  // "Refusé",
];

// Styled components
const TrackerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 20px;
  position: relative; // Needed for absolute positioning of the line
`;

const Step = styled.div<{ isActive: boolean }>`
  text-align: center;
  position: relative; // For positioning the pseudo-element
  width: 100%;

  &:not(:last-child)::after {
    content: "";
    width: calc(100% - 20px); // Adjust width to fit between circles
    height: 2px;
    background-color: ${(props) =>
      props.isActive ? "#4caf50" : "#ddd"}; // Default line color
    display: block;
    position: absolute;
    top: 15px;
    left: calc(50% + 10px); // Adjust to align with the center of the circle
    z-index: 0;
  }
`;

const StepCircle = styled.div<{ isActive: boolean }>`
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background-color: ${(props) => (props.isActive ? "#4caf50" : "#eee")};
  color: ${(props) => (props.isActive ? "white" : "black")};
  display: inline-block;
  margin-bottom: 5px;
  position: relative; // To overlap the line
  z-index: 1;

  // Change the line color to green for active steps
  & + ${Step}:not(:last-child)::after {
    background-color: ${(props) => (props.isActive ? "#4caf50" : "#ddd")};
  }
`;

const StepLabel = styled.div`
  display: block;
  font-size: 0.92rem;
  @media screen and (max-width: 682px) {
    display: none;
  }
`;

// Define the props type for StatusTracker
interface StatusTrackerProps {
  currentStatus: string; // Explicitly define the type of currentStatus as string
}

const StatusTracker: React.FC<StatusTrackerProps> = ({ currentStatus }) => {
  // getStatusIndex function with parameter type defined
  const getStatusIndex = (status: string) => statusSteps.indexOf(status);

  return (
    <TrackerContainer>
      {statusSteps.map((status, index) => (
        <Step key={status} isActive={getStatusIndex(currentStatus) > index}>
          <StepCircle isActive={getStatusIndex(currentStatus) >= index}>
            {index + 1}
          </StepCircle>
          <StepLabel>{status}</StepLabel>
        </Step>
      ))}
    </TrackerContainer>
  );
};

export default StatusTracker;
