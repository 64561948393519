import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
export interface ILogement {
  prenom: string; // First name
  nom: string; // Last name
  sexe: string; // Gender
  dateDeNaissance: string; // Date of birth
  email: string; // Email address
  numeroDeTelephone: string; // Phone number
  adresse: string;
  adresseComplement: string;
  codePostal: string;
  ville: string;
  documents: {
    [category: string]: {
      [key: string]: {
        createdAt: number;
        fileUrl: string;
      }; // key is the unique identifier, value is the URL
    };
  };
  numeroUnique: string; // Unique ID
  salaireBrut: string;
  salaireAvisImpot1: string;
  salaireAvisImpot2: string;
  situationFamiliale:
    | string
    | "célibataire"
    | "marié"
    | "séparé"
    | "divorcé"
    | "veuf"; // Family situation
  nombreDEnfants: number; // Number of children
  logementActuel: string | "sans domicile fixe" | "appartement" | "maison"; // Current housing
  typeDeLogementActuel: string | "studio" | "T2" | "T3" | "T4" | "T5" | "T6"; // Type of current housing
  quartierActuel: string; // Current neighborhood
  bailleurActuel:
    | string
    | "Pays d'Aix Habitat"
    | "Famille et Provence"
    | "Logirem"
    | "Sacogiva"
    | "13 Habitat"
    | "Unicil"
    | "Privé"; // Current landlord
  typeDeLogementSouhaite: string | "studio" | "T2" | "T3" | "T4" | "T5" | "T6"; // Desired type of housing
  quartierSouhaite: string[]; // Desired neighborhood
  dateDEnregistrement: string; // Registration date
  statutDeLaDemande:
    | string
    | "À traiter"
    | "En attente de documents"
    | "Demande envoyée"
    | "En attente"
    | "En attente d'attribution"
    | "Attribué"
    | "Refusé"; // Request status
  motivations: string; // Motivations
  commentaires: string; // Comments
}

export interface HousingRequestsState {
  request: ILogement;
  loading: boolean;
  error: string | null;
}

// Define the initial state using that type
const initialState: HousingRequestsState = {
  request: {
    prenom: "", // First name ok
    nom: "", // Last name ok
    sexe: "", // Gender ok
    dateDeNaissance: "", // Date of birth ok
    email: "", // Email address ok
    numeroDeTelephone: "", // Phone number ok
    adresse: "",
    adresseComplement: "",
    codePostal: "",
    ville: "",
    numeroUnique: "", // Unique ID ok
    documents: {},
    salaireBrut: "",
    salaireAvisImpot1: "",
    salaireAvisImpot2: "",
    situationFamiliale: "célibataire", // Family situation ok
    nombreDEnfants: 0, // Number of children ok
    logementActuel: "sans domicile fixe", // Current housing ok
    typeDeLogementActuel: "studio", // Type of current housing ok
    quartierActuel: "", // Current neighborhood ok
    bailleurActuel: "Pays d'Aix Habitat", // Current landlord ok
    typeDeLogementSouhaite: "studio", // Desired type of housing ok
    quartierSouhaite: [], // Desired neighborhood ok
    dateDEnregistrement: "", // Registration date
    statutDeLaDemande: "À traiter", // Request status
    motivations: "", // Motivations
    commentaires: "", // Comments
  },
  loading: false,
  error: null,
};

// Simple function to generate a unique ID
const generateUniqueId = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export const logementSlice = createSlice({
  name: "housingRequests",
  initialState,
  reducers: {
    addRequest: (state, action: PayloadAction<ILogement>) => {
      const newRequest = {
        ...action.payload,
        id: generateUniqueId(),
        dateDEnregistrement: new Date().toISOString(), // Set the current date in ISO format
      };
      state.request = newRequest;
    },
    updateRequestStatus: (
      state,
      action: PayloadAction<{ status: ILogement["statutDeLaDemande"] }>
    ) => {
      // Directly update the status of the current request
      if (state.request) {
        state.request.statutDeLaDemande = action.payload.status;
      }
    },
    editRequest: (state, action: PayloadAction<ILogement>) => {
      // Directly update the current request
      state.request = action.payload;
    },
    // Additional reducers as needed
    // ...
  },
});

// Export the actions
export const { addRequest, updateRequestStatus, editRequest } =
  logementSlice.actions;

export default logementSlice.reducer;
