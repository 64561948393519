// import eventIcon from "./assets/images/clapping-hands.png";
import assoIcon from "./assets/images/deal-done.png";
const routes = [
  {
    name: "Logement",
    key: "logement",
    route: "/logement",
    icon: assoIcon,
  },
  // {
  //   name: "Évènements",
  //   key: "evenements",
  //   route: "/events",
  //   icon: eventIcon,
  // },
];

export default routes;
