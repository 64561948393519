import { createSlice } from "@reduxjs/toolkit";

const initialStateUi = {
  drawerDesktop: true,
  drawerMobile: false,
};

export const UIReducer = createSlice({
  name: "UIReducer",
  initialState: initialStateUi,
  reducers: {
    openDrawerDesktop: (state, action) => {
      state.drawerDesktop = action.payload;
    },
    closeDrawerDesktop: (state, action) => {
      state.drawerDesktop = action.payload;
    },
    openDrawerMobile: (state, action) => {
      state.drawerMobile = action.payload;
    },
    closeDrawerMobile: (state, action) => {
      state.drawerMobile = action.payload;
    },
  },
});

export const {
  openDrawerDesktop,
  closeDrawerDesktop,
  openDrawerMobile,
  closeDrawerMobile,
} = UIReducer.actions;
