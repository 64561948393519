import { get, ref } from "firebase/database";
import { database } from "../../firebase/firebase";

export const readFollow = async (uid: string | null) => {
  let followingData: string[] = [];
  let followersData: string[] = [];
  try {
    await get(ref(database, `users/${uid}/personaldata/following`)).then(
      (snapshot) => {
        if (snapshot.exists()) {
          console.log("User Exists");
          const apiData = snapshot.val();
          const uidList: string[] = Object.values(apiData);
          console.log(apiData);
          for (let i = 0; i < uidList.length; i++) {
            followingData.push(uidList[i]);
          }
        } else {
          console.log(`Users doesn't exist`);
        }
      }
    );
    console.log(followingData);
    await get(ref(database, `users/${uid}/personaldata/followers`)).then(
      (snapshot) => {
        if (snapshot.exists()) {
          console.log("User Exists");
          const apiData = snapshot.val();
          const uidList: string[] = Object.values(apiData);
          for (let i = 0; i < uidList.length; i++) {
            followersData.push(uidList[i]);
          }
        } else {
          console.log(`Users doesn't exist`);
        }
      }
    );
    console.log(followersData);
  } catch (error) {
    console.log(error);
  }
  return { followers: followersData, following: followingData };
};
