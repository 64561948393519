import React, {
  useState,
  useEffect,
  ChangeEvent,
  Dispatch,
  SetStateAction,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import readLogementById from "../../utils/api/readLogementById";
import { ILogement } from "../../store/applications/logementReducer";
import {
  NeumorphicButton,
  SuccessMessage,
  initialLogementState,
} from "./LogementAdmin";
// import writeLogementUploadFiles from "../../utils/api/writeLogementUploadFiles";
import { FlexRowEnd } from "../../components/UI/Flex/Flex";
import { ThreeDots } from "react-loader-spinner";
import ButtonNeumorphicRoot from "../../components/UI/ButtonNeumorphic/ButtonNeumorphic.styled";
import StatusTracker from "../../components/StatusTracker/StatusTracker";
import writeLogementUploadFiles from "../../utils/api/writeLogementUploadFiles";

// Styled components

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  gap: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Column = styled.div`
  display: grid;
  flex: 1;
  max-width: 50%;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    max-width: none;
  }
`;

const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 1px solid #eee;
`;

const Label = styled.span`
  color: #555;
`;

const Value = styled.span`
  font-weight: bold;
  /* color: #666; */
`;

// const FileUploadContainer = styled.div`
//   padding: 20px;
//   background: white;
//   border-radius: 8px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// `;

const FileInputLabel = styled.label`
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #555;
`;

const FileInput = styled.input`
  width: 100%;
  margin-bottom: 20px;
`;

const Error = styled.p`
  color: red;
  text-align: center;
`;

const Loading = styled.p`
  text-align: center;
`;

const FilePreview = styled.div`
  margin-bottom: 10px;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 5px;
`;

const FilePreviewFrame = styled.iframe`
  width: 100%;
  height: 300px; // You can adjust the height as needed
  border: none;
  margin-bottom: 10px;
`;

const RemoveButton = styled.button`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: darkred;
  }
`;

const FileContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

// Component
const LogementDetail = () => {
  const navigate = useNavigate();
  const { numeroUnique } = useParams();
  const [logement, setLogement] = useState<ILogement>(initialLogementState);
  const [isFetched, setIsFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [livretFiles, setLivretFiles] = useState<File[]>([]);
  const [identityFiles, setIdentityFiles] = useState<File[]>([]);
  const [taxFiles, setTaxFiles] = useState<File[]>([]);
  const [salaryFiles, setSalaryFiles] = useState<File[]>([]);
  const [otherFiles, setOtherFiles] = useState<File[]>([]);

  // State for submission message
  // eslint-disable-next-line
  const [isSubmitted, setIsSubmitted] = useState(false);
  // State for success message
  // eslint-disable-next-line
  const [isSuccess, setIsSuccess] = useState(false);

  const handleFileChange =
    (setFilesFunction: React.Dispatch<React.SetStateAction<File[]>>) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const filteredFiles = Array.from(e.target.files).filter((file) =>
          ["application/pdf", "image/jpeg", "image/png"].includes(file.type)
        );
        setFilesFunction((prevFiles) => [...prevFiles, ...filteredFiles]);
      }
    };

  useEffect(() => {
    const fetchLogement = async () => {
      setLoading(true);
      console.log(numeroUnique);
      try {
        const response = numeroUnique && (await readLogementById(numeroUnique));
        console.log(response);
        setLogement(response);
        setIsFetched(true);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchLogement();
    console.log(logement);
  }, [numeroUnique, isFetched]); // eslint-disable-line

  const removeFile = (
    fileToRemove: File,
    setFilesFunction: React.Dispatch<React.SetStateAction<File[]>>
  ) => {
    setFilesFunction((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  };

  const renderFilePreview = (
    file: File,
    setFilesFunction: React.Dispatch<React.SetStateAction<File[]>>
  ) => {
    return (
      <FileContainer key={file.name}>
        {file.type.includes("image") ? (
          <ImagePreview src={URL.createObjectURL(file)} alt={file.name} />
        ) : (
          <FilePreviewFrame src={URL.createObjectURL(file)} />
        )}
        <RemoveButton onClick={() => removeFile(file, setFilesFunction)}>
          Retirer
        </RemoveButton>
      </FileContainer>
    );
  };

  const renderFilePreviewUploaded = (file: {
    createdAt: number;
    fileUrl: string;
  }) => {
    return (
      <FileContainer>
        {file.fileUrl.endsWith(".pdf?alt=media") ? (
          <FilePreviewFrame src={file.fileUrl} />
        ) : (
          <ImagePreview
            src={file.fileUrl}
            alt={`Aperçu des pièces justificatives ${file.fileUrl}`}
          />
        )}
      </FileContainer>
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);

    try {
      const response =
        numeroUnique &&
        (await writeLogementUploadFiles(numeroUnique, {
          livret: livretFiles,
          identity: identityFiles,
          tax: taxFiles,
          salary: salaryFiles,
          other: otherFiles,
        }));
      console.log("Files uploaded successfully", response);
      setIsSuccess(true); // Set success message state
      setIsSubmitted(false);
      setIsFetched(false);
      setTimeout(() => setIsSuccess(false), 5000); // Hide message after 5 seconds
      // Handle successful upload: maybe clear the state or show a success message
    } catch (error) {
      console.error("Error uploading files:", error);
      setIsSubmitted(false);
      // Handle errors: show error messages as needed
    }
  };

  // Helper function to create a card for each document type
  const renderDocumentCard = (
    label: string,
    category: string,
    fileState: File[],
    setFileState: Dispatch<SetStateAction<File[]>>
  ) => {
    return (
      <Card>
        <FileInputLabel>{label}</FileInputLabel>
        <FileInput
          type="file"
          multiple
          onChange={handleFileChange(setFileState)}
          accept=".pdf,.jpeg,.jpg,.png"
        />
        {fileState.map((file, index) => (
          <FilePreview key={index}>
            {renderFilePreview(file, setFileState)}
          </FilePreview>
        ))}
        {isSubmitted ? (
          <FlexRowEnd>
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#3457D5"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </FlexRowEnd>
        ) : (
          <FlexRowEnd>
            <NeumorphicButton onClick={handleSubmit} type="submit">
              Téléverser les documents
            </NeumorphicButton>
          </FlexRowEnd>
        )}
        {isSuccess && (
          <SuccessMessage>
            Pièces jointes téléversées avec succès !
          </SuccessMessage>
        )}
        {/* {logement.documents && (
          <Card>
            {Object.entries(logement.documents || {}).map(
              ([category, files]) => (
                <div key={category}>
                  <h3>{category}</h3>
                  <div>
                    {Object.values(files).map((fileUrl, index) => (
                      <FilePreview key={index}>
                        {renderFilePreviewUploaded(fileUrl)}
                      </FilePreview>
                    ))}
                  </div>
                </div>
              )
            )}
          </Card>
        )} */}
        {logement.documents?.[category.toLowerCase()] &&
          Object.values(logement.documents[category.toLowerCase()]).map(
            (fileUrl, index) => (
              <FilePreview key={index}>
                {renderFilePreviewUploaded(fileUrl)}
              </FilePreview>
            )
          )}
      </Card>
    );
  };

  if (loading) return <Loading>Loading...</Loading>;
  if (error) return <Error>Error: {error}</Error>;

  return (
    <div>
      <ButtonNeumorphicRoot onClick={() => navigate(-1)}>
        <span className="material-icons" style={{ fontSize: "18px" }}>
          arrow_back
        </span>
      </ButtonNeumorphicRoot>

      <Container>
        {logement ? (
          <>
            <StatusTracker currentStatus={logement.statutDeLaDemande} />
            <Layout>
              <Column>
                <Card>
                  <Title>{`${logement.prenom} ${
                    logement.nom
                  } - Demande de logement - ${logement.typeDeLogementSouhaite.toUpperCase()}`}</Title>
                  {/* Render logement details or loading/error message */}
                  {Object.entries(logement).map(([key, value]) => {
                    if (key === "quartierSouhaite" && Array.isArray(value)) {
                      console.log(value);
                      return (
                        <DetailRow key={key}>
                          <Label>{key}:</Label>
                          <div>
                            {value.map((element, index) => (
                              <p key={index}>
                                <Value>{element}</Value>
                              </p>
                            ))}
                          </div>
                        </DetailRow>
                      );
                    } else if (key !== "documents") {
                      return (
                        <DetailRow key={key}>
                          <Label>{key}:</Label>
                          <Value>{value}</Value>
                        </DetailRow>
                      );
                    }
                    return null; // For 'documents' or any other keys you might want to exclude
                  })}
                </Card>
              </Column>
              <Column>
                {/* Separate cards for each document type */}
                {renderDocumentCard(
                  "Livret de famille",
                  "livret",
                  livretFiles,
                  setLivretFiles
                )}
                {renderDocumentCard(
                  "Carte d'identité",
                  "identity",
                  identityFiles,
                  setIdentityFiles
                )}
                {renderDocumentCard(
                  "Avis d'imposition",
                  "tax",
                  taxFiles,
                  setTaxFiles
                )}
                {renderDocumentCard(
                  "Bulletins de salaire",
                  "salary",
                  salaryFiles,
                  setSalaryFiles
                )}
                {renderDocumentCard(
                  "Toutes autres pièces justificatives",
                  "other",
                  otherFiles,
                  setOtherFiles
                )}
              </Column>
            </Layout>
          </>
        ) : (
          <Card>
            <Error>
              Il n'existe pas de demande de logement pour ce numéro unique
            </Error>
          </Card>
        )}
      </Container>
    </div>
  );
};

export default LogementDetail;
