import React from "react";
import styled from "styled-components";

export const FooterBox = styled.div`
  /* width: 100%; */
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  opacity: 1;
  background: transparent;
  color: #777;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row !important;
  }
`;

export const FooterCopyrightBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.875rem;
  padding-left: 12px;
  padding-right: 12px;
  opacity: 1;
  background: transparent;
  color: #777;
`;

export const FooterLinksBox = styled.ul`
  opacity: 1;
  background: transparent;
  color: #777;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin-top: 24px;
  margin-bottom: 0px;
  padding: 0px;
  list-style-type: none !important;

  @media screen and (min-width: 992px) {
    margin-top: 0px !important;
  }
`;

function Footer() {
  return (
    <FooterBox>
      <FooterCopyrightBox>
        © {new Date().getFullYear()}, made with &nbsp;
        <span
          style={{ color: "red", fontSize: "inherit" }}
          className="material-icons"
        >
          favorite
        </span>
        &nbsp;by&nbsp;
        <a
          className="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineNone css-1tqdl5x"
          href="https://www.linkedin.com/in/salah-eddine-khouiel-b7137ba5/"
          target="_blank"
          rel="noreferrer"
        >
          <span className="MuiTypography-root MuiTypography-button css-1w3klud">
            Salah Khouiel
          </span>
        </a>
      </FooterCopyrightBox>
      <FooterLinksBox>Links</FooterLinksBox>
    </FooterBox>
  );
}

export default Footer;
