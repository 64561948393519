// PageTitle.tsx
import React, { ReactNode } from "react";
import styled from "styled-components";
import { useTheme } from "../../context/ThemeContext";

const StyledPageTitle = styled.h1`
  color: ${(props) => props.theme.headline};
  font-size: 1rem;
  margin: 0.5rem 0;
  font-family: "DM Serif Text", "Roboto";
`;

const PageTitle: React.FC<{ children: ReactNode }> = ({ children }) => {
  const theme = useTheme();

  return <StyledPageTitle theme={theme}>{children}</StyledPageTitle>;
};

export default PageTitle;
