import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Palette } from "../../assets/style/colors";
import logo from "../../assets/images/hand-logo.webp";
import { useTheme } from "../../context/ThemeContext";
import { MQEA } from "../UI/Text/mqea/mqea.styled";
import { FlexColCenter, GridThreeCol } from "../UI/Flex/Flex";

// Define our primary and secondary colors
export const ContentWrapper = styled.div`
  text-align: center;
  max-width: 1200px; // Max width for the content
`;

export const HeroContainer = styled.section<{ theme: Palette }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.headline};
  min-height: 90vh;
  padding: 10px;
`;

export const Title = styled.h1<{ theme: Palette }>`
  font-size: 1.4rem;
  color: ${(props) => props.theme.headline};
  margin-top: 0.4rem;
  margin-bottom: 0.4em;
`;

export const Subtitle = styled.p<{ theme: Palette }>`
  font-size: 1rem;
  margin-top: 20px;
  color: ${(props) => props.theme.paragraph};
`;

export const StyledLink = styled(Link)<{ theme: Palette }>`
  display: inline-block;
  background: linear-gradient(
    45deg,
    ${(props) => props.theme.button},
    ${(props) => props.theme.button2}
  );
  color: ${(props) => props.theme.buttonText};
  text-transform: uppercase;
  padding: 10px 20px;
  margin-top: 30px;
  border: none;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) =>
      props.theme.primaryColor}; // Define primaryColor in theme
    color: ${(props) =>
      props.theme.secondaryColor}; // Define secondaryColor in theme
    cursor: pointer;
  }
`;

export const ButtonMQEA = styled.button<{ theme: Palette }>`
  display: inline-block;
  font-family: "DM Serif Text" !important;
  background: linear-gradient(
    45deg,
    ${(props) => props.theme.button},
    ${(props) => props.theme.button2}
  );
  color: ${(props) => props.theme.buttonText};
  text-transform: uppercase;
  padding: 10px 20px;
  margin-top: 30px;
  border: none;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) =>
      props.theme.primaryColor}; // Define primaryColor in theme
    color: ${(props) =>
      props.theme.secondaryColor}; // Define secondaryColor in theme
    cursor: pointer;
  }
`;

// Logo remains unchanged
export const Logo = styled.img`
  max-width: 200px;
  width: 100%;

  @media screen and (max-width: 482px) {
    max-width: 150px;
  }
`;

// Hero section component
const Hero = () => {
  const theme = useTheme();

  return (
    <HeroContainer theme={theme}>
      <ContentWrapper>
        <Logo src={logo} alt="monquartierenaction logo" />
        <Title theme={theme}>
          <MQEA>MonQuartierEnAction</MQEA> permet à tous les habitants de se
          sentir pleinement citoyens.
        </Title>
        <Subtitle theme={theme}>
          Vous êtes bénévole, vous avez des idées ou vous souhaitez soutenir une
          action ? Grâce à <MQEA>MonQuartierEnAction</MQEA> exprimez votre
          citoyenneté pour un meilleur quartier, plus vivant, plus agréable et
          plus animé.
        </Subtitle>
        <GridThreeCol>
          <FlexColCenter>
            <StyledLink to="/logement" theme={theme}>
              Logement Social
            </StyledLink>
            <Subtitle theme={theme}>
              Pour solliciter votre mairie de quartier concernant votre demande
              de logement social.
            </Subtitle>
          </FlexColCenter>

          <FlexColCenter>
            <StyledLink to="/technique" theme={theme}>
              Route / Éclairage / Propreté
            </StyledLink>
            <Subtitle theme={theme}>
              Signalez-nous les dysfonctionnements techniques dans le quartier.
            </Subtitle>
          </FlexColCenter>

          <FlexColCenter>
            <StyledLink to="/securite" theme={theme}>
              Sécurité
            </StyledLink>
            <Subtitle theme={theme}>
              Êtes-vous témoin d'un problème de sécurité ? Signalez-le nous!
            </Subtitle>
          </FlexColCenter>
        </GridThreeCol>
      </ContentWrapper>
    </HeroContainer>
  );
};

export default Hero;
