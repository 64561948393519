import styled from "styled-components";
export interface IModal {
  isDisplay: boolean;
}

export const ModalBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: ${(props: IModal) => (props.isDisplay ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 10;
  min-height: 100vh;
  width: 100vw;

  @media screen and (max-width: 682px) {
    min-height: 160vh;
  }
`;

export const ModalLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 11;
  background-color: rgb(0 0 0/30%);
  min-height: 100vh;
  width: 100vw;

  @media screen and (max-width: 682px) {
    min-height: 160vh;
  }
`;

export const Modal = styled.div`
  backdrop-filter: saturate(200%) blur(1.875rem);
  background-color: rgb(255 255 255/60%);
  min-width: 50vw;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 15px;
  border-radius: 2rem;
  z-index: 100;
  position: absolute;

  @media screen and (max-width: 682px) {
    bottom: 30vh;
  }
`;
