import { push, ref, set } from "firebase/database";
import { transformText } from "..";
import { database } from "../../firebase/firebase";

export interface IUserData {
  uid: string;
  pic: string;
  nom: string;
  prenom: string;
  gender: string;
  birthday: string;
  pays: string;
  ville: string;
  quartier: string;
  residence: string;
  rue: string;
  voisins: string[];
  posts: number[];
  events: number[];
  projects: number[];
  email: string;
  emailVerified: boolean;
}

export interface IUserPublicData {
  uid: string;
  pic: string;
  nom: string;
  prenom: string;
  gender: string;
  pays: string;
  ville: string;
  quartier: string;
  voisins: string[];
  posts: number[];
  events: number[];
  projects: number[];
  emailVerified: boolean;
}

export const writeNewUser = async (
  newUserData: IUserData,
  uid: string | null,
  friendUid?: string
) => {
  console.log(`users/${uid}/personalData`);
  let publicdataObj: IUserPublicData = {
    pic: "https://firebasestorage.googleapis.com/v0/b/monquartierenaction.appspot.com/o/user-pic.webp?alt=media&token=3c1ce4f2-d2fb-4f34-9179-f1b0d9b364df",
    nom: newUserData.nom,
    prenom: newUserData.prenom,
    gender: newUserData.gender,
    quartier: newUserData.quartier,
    ville: newUserData.ville,
    pays: newUserData.pays,
    emailVerified: newUserData.emailVerified,
    uid: newUserData.uid,
    voisins: [],
    posts: [],
    events: [],
    projects: [],
  };
  try {
    set(ref(database, `users/${uid}/personaldata`), newUserData);
    set(ref(database, `users/${uid}/publicdata`), publicdataObj);
    set(push(ref(database, `users/${uid}/personaldata/followers`)), friendUid);
    set(push(ref(database, `users/${uid}/publicdata/followers`)), friendUid);
    set(push(ref(database, `users/${uid}/personaldata/following`)), friendUid);
    set(push(ref(database, `users/${uid}/publicdata/following`)), friendUid);
    if (typeof friendUid !== "undefined") {
      set(
        push(ref(database, `users/${friendUid}/personaldata/followers`)),
        uid
      );
      set(push(ref(database, `users/${friendUid}/publicdata/followers`)), uid);
      set(
        push(ref(database, `users/${friendUid}/personaldata/following`)),
        uid
      );
      set(push(ref(database, `users/${friendUid}/publicdata/following`)), uid);
    }
    // add user to collection Quartier
    set(
      push(
        ref(
          database,
          `quartiers/${transformText(newUserData.quartier)}/habitants`
        )
      ),
      uid
    );
    // add user to collection Ville
    set(
      push(
        ref(database, `villes/${transformText(newUserData.ville)}/habitants`)
      ),
      uid
    );
    // get(
    //   ref(
    //     database,
    //     `quartiers/${transformText(newUserData.quartier)}/habitants`
    //   )
    // ).then((snapshot) => {
    //   if (snapshot.exists()) {
    //     console.log("Habitant Exists");
    //     const apiData = snapshot.val();
    //     const habitantsLength = apiData.length;
    //     set(
    //       ref(
    //         database,
    //         `quartiers/${transformText(
    //           newUserData.quartier
    //         )}/habitants/${habitantsLength}`
    //       ),
    //       uid
    //     );
    //   } else {
    //     console.log(`Users doesn't exist`);
    //     set(
    //       ref(
    //         database,
    //         `quartiers/${transformText(newUserData.quartier)}/habitants/0`
    //       ),
    //       uid
    //     );
    //   }
    // });

    // add user to collection Ville
    // get(
    //   ref(database, `villes/${transformText(newUserData.ville)}/habitants`)
    // ).then((snapshot) => {
    //   if (snapshot.exists()) {
    //     console.log("Habitant Exists");
    //     const apiData = snapshot.val();
    //     const habitantsLength = apiData.length;
    //     set(
    //       ref(
    //         database,
    //         `villes/${transformText(
    //           newUserData.ville
    //         )}/habitants/${habitantsLength}`
    //       ),
    //       uid
    //     );
    //   } else {
    //     console.log(`Users doesn't exist`);
    //     set(
    //       ref(
    //         database,
    //         `villes/${transformText(newUserData.ville)}/habitants/0`
    //       ),
    //       uid
    //     );
    //   }
    // });
  } catch (error) {
    console.log(error);
  }
};
