import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import styled from "styled-components";
import {
  ILogement,
  addRequest,
} from "../../store/applications/logementReducer";
import { Label } from "../../components/UI/Forms/Forms.styled";
import writeLogementNew from "../../utils/api/writeLogementNew";
import {
  FlexColStart,
  FlexRowEnd,
  FlexRowStart,
} from "../../components/UI/Flex/Flex";
import { ThreeDots } from "react-loader-spinner";
import readLogementById from "../../utils/api/readLogementById";
import { useNavigate } from "react-router-dom";
import ButtonNeumorphicRoot from "../../components/UI/ButtonNeumorphic/ButtonNeumorphic.styled";
import { loadAuth, loadAuthToken } from "../../store/applications/auth";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase/firebase";

// Styled components
const Form = styled.form`
  // Add your form styling here
`;

// Neumorphic container
const NeumorphicContainer = styled.div`
  // background: #e0e0e0; // light gray background
  background-color: transparent !important;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 8px 8px 15px #a3a3a3, -8px -8px 15px #ffffff; // soft shadows for 3D effect
  max-width: 500px; // Adjust as needed
  margin: auto;
`;

// Neumorphic input
const NeumorphicInput = styled.input`
  background: #e0e0e0;
  border: none;
  border-radius: 15px;
  padding: 10px 15px;
  margin: 10px 5px;
  box-shadow: inset 6px 6px 10px #a3a3a3, inset -6px -6px 10px #ffffff;
  &:focus {
    outline: none;
    box-shadow: inset 8px 8px 10px #a3a3a3, inset -8px -8px 10px #ffffff;
  }
`;

// Neumorphic button
export let NeumorphicButton = styled.button`
  background: #e0e0e0;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 10px 0;
  box-shadow: 5px 5px 10px #a3a3a3, -5px -5px 10px #ffffff;
  cursor: pointer;
  &:hover {
    box-shadow: 2px 2px 5px #a3a3a3, -2px -2px 5px #ffffff;
  }
  &:active {
    box-shadow: inset 1px 1px 2px #a3a3a3, inset -1px -1px 2px #ffffff;
  }
`;

const NeumorphicSelect = styled.select`
  background: #e0e0e0;
  border: none;
  border-radius: 15px;
  padding: 10px 15px;
  margin: 10px 5px;
  box-shadow: inset 6px 6px 10px #a3a3a3, inset -6px -6px 10px #ffffff;
  &:focus {
    outline: none;
    box-shadow: inset 8px 8px 15px #a3a3a3, inset -8px -8px 15px #ffffff;
  }
`;

const NeumorphicTextarea = styled.textarea`
  background: #e0e0e0;
  border: none;
  border-radius: 15px;
  padding: 15px;
  margin: 10px 0;
  box-shadow: inset 6px 6px 10px #a3a3a3, inset -6px -6px 10px #ffffff;
  resize: vertical; // Allow vertical resizing
  &:focus {
    outline: none;
    box-shadow: inset 8px 8px 15px #a3a3a3, inset -8px -8px 15px #ffffff;
  }
`;

export const SuccessMessage = styled.div`
  color: #03c03c;
  margin-top: 10px;
  text-align: center;
`;

export const ErrorMessage = styled.div`
  color: #990000;
  margin-top: 10px;
  text-align: center;
`;

// Add CSS animation for button click
const animateButton = `
  @keyframes clickAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(1);
    }
  }
`;

NeumorphicButton = styled(NeumorphicButton)`
  ${animateButton}
  &:active {
    animation: clickAnimation 0.4s ease;
  }
`;

const AccordionContainer = styled.div`
  background: #e0e0e0;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #a3a3a3, -5px -5px 10px #ffffff;
  margin: 10px 0;
  overflow: hidden;
`;

const AccordionHeader = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  user-select: none;
  font-weight: bold;
  color: #333;
  &:hover {
    background: #d1d1d1;
  }
`;

const AccordionContent = styled.div`
  padding: 10px 20px;
  background: #e0e0e0;
  display: flex;
  flex-direction: column;
`;

const CheckboxLabel = styled.label`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const AutocompleteNeumorphic = styled(Autocomplete)`
  background: #e0e0e0;
  border: none;
  border-radius: 15px;
  padding: 10px 15px;
  margin: 10px 5px;
  box-shadow: inset 6px 6px 10px #a3a3a3, inset -6px -6px 10px #ffffff;
  &:focus {
    outline: none;
    box-shadow: inset 8px 8px 10px #a3a3a3, inset -8px -8px 10px #ffffff;
  }
`;

// Options for the quartier
const quartierOptions = [
  "pasDePreference",
  "celony",
  "centreVille",
  "encagnane",
  "jasDeBouffan",
  "duranne",
  "beisson",
  "stEutrope",
  "platanes",
  "lesMilles",
  "luynes",
  "pontDeBeraud",
  "pontDeLArc",
  "puyricardCouteron",
  "sextiusMirabeau",
  "stMitreGranettes",
  "valStAndreTorse",
];

export const initialLogementState = {
  id: "", // Unique identifier for the request
  prenom: "", // First name ok
  nom: "", // Last name ok
  sexe: "", // Gender ok
  dateDeNaissance: "", // Date of birth ok
  email: "", // Email address ok
  numeroDeTelephone: "", // Phone number ok
  adresse: "",
  adresseComplement: "",
  codePostal: "",
  ville: "",
  numeroUnique: "", // Unique ID ok
  documents: {},
  salaireBrut: "",
  salaireAvisImpot1: "",
  salaireAvisImpot2: "",
  situationFamiliale: "célibataire", // Family situation ok
  nombreDEnfants: 0, // Number of children ok
  logementActuel: "sans domicile fixe", // Current housing ok
  typeDeLogementActuel: "studio", // Type of current housing ok
  quartierActuel: "", // Current neighborhood ok
  bailleurActuel: "Pays d'Aix Habitat", // Current landlord ok
  typeDeLogementSouhaite: "studio", // Desired type of housing ok
  quartierSouhaite: [], // Desired neighborhood ok
  dateDEnregistrement: "", // Registration date
  statutDeLaDemande: "En attente des documents", // Request status
  motivations: "", // Motivations
  commentaires: "", // Comments
};

// Logement component
const LogementAdmin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authData = useAppSelector((state) => state.AuthReducer);

  // State for submission message
  const [isSubmitted, setIsSubmitted] = useState(false);
  // State for success message
  const [isSuccess, setIsSuccess] = useState(false);
  // State for error message
  const [isError, setIsError] = useState(false);

  // State for form fields
  const [formData, setFormData] = useState<ILogement>(initialLogementState);

  // eslint-disable-next-line
  const [isAuth, setIsAuth] = useState(
    false || window.localStorage.getItem("auth-mqea") === "true"
  );
  const [token, setToken] = useState("");

  useEffect(() => {
    dispatch(loadAuth());
    if (isAuth) {
      onAuthStateChanged(auth, (userCred) => {
        userCred?.getIdToken().then((token) => {
          dispatch(loadAuthToken(token));
          setToken(token);
        });
      });
    }
  }, [token, authData.uid]); // eslint-disable-line

  // Handle form input changes
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    setIsError(false);
    const { name, value } = e.target;
    console.log(name, value);

    let modifiedValue = value.toLowerCase(); // Convert all string fields to lowercase

    if (name === "numeroUnique") {
      modifiedValue = modifiedValue.replace(/\s+/g, ""); // Remove white spaces for numeroUnique
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: modifiedValue,
    }));
  };

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const handleQuartierChange = (quartier: string) => {
    setFormData((prevFormData) => {
      const quartierSelection = prevFormData.quartierSouhaite;
      if (quartierSelection.includes(quartier)) {
        return {
          ...prevFormData,
          quartierSouhaite: quartierSelection.filter((q) => q !== quartier),
        };
      } else {
        return {
          ...prevFormData,
          quartierSouhaite: [...quartierSelection, quartier],
        };
      }
    });
  };

  // Function to handle address selection from Google Autocomplete
  const handlePlaceSelected = (
    place: google.maps.places.PlaceResult
    // ,
    // inputName: keyof ILogement
  ) => {
    // Use the types to get address components and other information
    const addressComponents = place.address_components;
    console.log(addressComponents);
    // const streetNumber =
    //   addressComponents?.find((component) =>
    //     component.types.includes("street_number")
    //   )?.long_name || "";
    // const address =
    //   addressComponents?.find((component) => component.types.includes("route"))
    //     ?.long_name || "";
    // const city =
    //   addressComponents?.find((component) =>
    //     component.types.includes("locality")
    //   )?.long_name || "";
    // const postalCode =
    //   addressComponents?.find((component) =>
    //     component.types.includes("postal_code")
    //   )?.long_name || "";

    if (addressComponents) {
      console.log("addressComponents");
      // Proceed with processing the address components
      const addressObject = addressComponents.reduce((acc, current) => {
        const type = current.types[0];
        acc[type] = current.long_name;
        return acc;
      }, {} as { [key: string]: string });

      // Update the form state with the new address information
      setFormData((prevState) => ({
        ...prevState,
        adresse: `${addressObject["street_number"] || ""} ${
          addressObject["route"] || ""
        }`.trim(),
        adresseComplement: addressObject["subpremise"] || "",
        codePostal: addressObject["postal_code"] || "",
        ville:
          addressObject["locality"] ||
          addressObject["administrative_area_level_1"] ||
          "",
      }));

      console.log(formData);
    } else {
      // Handle the case where address_components is undefined
      console.error("Address components are undefined");
      // You may want to set an error state or display a notification to the user
    }
  };

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  // Form submission
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitted(true);
    setIsError(false);

    // Check if numeroUnique already exists
    try {
      const response = await readLogementById(formData.numeroUnique);
      console.log(response);
      if (response) {
        setIsSubmitted(false);
        setIsError(true);
        console.error("Logement with this numeroUnique already exists.");
        return; // Prevent further execution
      } else {
        try {
          // Call the API to submit the form data
          const response = await writeLogementNew(formData);
          console.log("Form submitted successfully:", response);

          // Dispatch action to add housing request (if still needed)
          dispatch(addRequest(formData));

          setIsSuccess(true); // Set success message state
          setIsSubmitted(false);
          setTimeout(() => setIsSuccess(false), 5000); // Hide message after 5 seconds
          // Reset form or redirect user
          // ... Reset logic or redirection
        } catch (error) {
          console.error("Error submitting form:", error);
          setIsSubmitted(false); // Ensure success message is not shown on error
          // Handle error (show error message, etc.)
        }
      }
    } catch (error) {
      setIsSubmitted(false);
      console.error("Error submitting form:", error);
    }
    // Validate form data
    // ... Validation logic here
  };

  return (
    <div>
      <ButtonNeumorphicRoot onClick={() => navigate(-1)}>
        <span className="material-icons" style={{ fontSize: "18px" }}>
          arrow_back
        </span>
      </ButtonNeumorphicRoot>

      <NeumorphicContainer>
        <Form onSubmit={handleSubmit}>
          <NeumorphicSelect
            // required
            name="sexe"
            value={formData.sexe}
            onChange={handleChange}
          >
            <option value="">Sexe</option>
            <option value="homme">Homme</option>
            <option value="femme">Femme</option>
          </NeumorphicSelect>
          <NeumorphicInput
            required
            name="prenom"
            value={formData.prenom}
            onChange={handleChange}
            placeholder="Prénom"
          />
          <NeumorphicInput
            // required
            name="nom"
            value={formData.nom}
            onChange={handleChange}
            placeholder="Nom"
          />
          <FlexColStart>
            <Label>Date de naissance</Label>
            <NeumorphicInput
              // required
              name="dateDeNaissance"
              value={formData.dateDeNaissance}
              onChange={handleChange}
              placeholder="Date de Naissance"
              type="date"
            />
          </FlexColStart>
          <FlexColStart>
            <Label>Numéro Unique</Label>
            <NeumorphicInput
              // required
              name="numeroUnique"
              value={formData.numeroUnique}
              onChange={handleChange}
              placeholder="Numéro Unique"
            />
          </FlexColStart>
          <FlexColStart>
            <Label>Salaire Brut</Label>
            <NeumorphicInput
              // required
              name="salaireBrut"
              value={formData.salaireBrut}
              onChange={handleChange}
              placeholder="Salaire Brut"
            />
          </FlexColStart>
          <FlexColStart>
            <Label>Salaire Brut Avis d'Impôt N-1</Label>
            <NeumorphicInput
              // required
              name="salaireAvisImpot1"
              value={formData.salaireAvisImpot1}
              onChange={handleChange}
              placeholder="Salaire Brut Avis d'Impôt N-1"
            />
          </FlexColStart>
          <FlexColStart>
            <Label>Salaire Brut Avis d'Impôt N-2</Label>
            <NeumorphicInput
              // required
              name="salaireAvisImpot2"
              value={formData.salaireAvisImpot2}
              onChange={handleChange}
              placeholder="Salaire Brut Avis d'Impôt N-2"
            />
          </FlexColStart>

          <FlexColStart>
            <Label>Email</Label>
            <NeumorphicInput
              required
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
            />
          </FlexColStart>
          <FlexColStart>
            <Label>Numéro de Téléphone</Label>
            <NeumorphicInput
              // required
              name="numeroDeTelephone"
              value={formData.numeroDeTelephone}
              onChange={handleChange}
              placeholder="Téléphone"
            />
          </FlexColStart>

          <FlexColStart>
            <Label>Adresse</Label>
            <AutocompleteNeumorphic
              apiKey="AIzaSyDuW2unjBIAdKtWOFXqHS_bxyq_RreVbdQ" // Replace with your API key
              onPlaceSelected={(place: google.maps.places.PlaceResult) =>
                handlePlaceSelected(place)
              }
              options={{
                types: ["address"],
                componentRestrictions: { country: "fr" },
                type: "",
              }}
              // fields={["address_components", "formatted_address"]}
              placeholder="Adresse"
            />
          </FlexColStart>
          {/* <FlexColStart>
            <Label>Adresse</Label>
            <NeumorphicInput
              name="adresse"
              value={formData.adresse}
              onChange={handleChange}
              placeholder="Code postal"
            />
          </FlexColStart> */}
          <FlexColStart>
            <Label>Complément d'adresse</Label>
            <NeumorphicInput
              name="adresseComplement"
              value={formData.adresseComplement}
              onChange={handleChange}
              placeholder="Complément d'adresse"
            />
          </FlexColStart>

          <FlexRowStart>
            <FlexColStart>
              <Label>Code postal</Label>
              <NeumorphicInput
                name="codePostal"
                value={formData.codePostal}
                onChange={handleChange}
                placeholder="Code postal"
              />
            </FlexColStart>
            <FlexColStart>
              <Label>Ville</Label>
              <NeumorphicInput
                name="ville"
                value={formData.ville}
                onChange={handleChange}
                placeholder="Ville"
              />
            </FlexColStart>
          </FlexRowStart>

          <FlexColStart>
            <Label>Situation Familiale</Label>
            <NeumorphicSelect
              // required
              name="situationFamiliale"
              value={formData.situationFamiliale}
              onChange={handleChange}
            >
              <option value="">Situation Familiale</option>
              <option value="celibataire">Célibataire</option>
              <option value="concubinage">Concubinage</option>
              <option value="pacse">Pacsé.e</option>
              <option value="marie">Marié.e</option>
              <option value="separe">Séparé.e</option>
              <option value="divorce">Divorcé.e</option>
              <option value="veuf">Veuf.ve</option>
            </NeumorphicSelect>
          </FlexColStart>
          <FlexColStart>
            <Label>Nombre d'enfants</Label>
            <NeumorphicSelect
              // required
              name="nombreDEnfants"
              value={formData.nombreDEnfants}
              onChange={handleChange}
            >
              <option value="">Nombre d'enfant</option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
            </NeumorphicSelect>
          </FlexColStart>
          <FlexColStart>
            <Label>Logement actuel</Label>
            <NeumorphicSelect
              // required
              name="logementActuel"
              value={formData.logementActuel}
              onChange={handleChange}
            >
              <option value="">Logement actuel</option>
              <option value="sans domicile fixe">Sans Domicile Fixe</option>
              <option value="appartement">Appartement</option>
              <option value="maison">Maison</option>
              <option value="centre hebergement">Centre d'hébergement</option>
            </NeumorphicSelect>
          </FlexColStart>
          <FlexColStart>
            <Label>Type du logement actuel</Label>
            <NeumorphicSelect
              // required
              name="typeDeLogementActuel"
              value={formData.typeDeLogementActuel}
              onChange={handleChange}
            >
              <option value="">Type de logement actuel</option>
              <option value="studio">Studio</option>
              <option value="t2">T2</option>
              <option value="t3">T3</option>
              <option value="t4">T4</option>
              <option value="t5">T5</option>
              <option value="t6">T6</option>
            </NeumorphicSelect>
          </FlexColStart>
          <FlexColStart>
            <Label>Quartier actuel</Label>
            <NeumorphicSelect
              // required
              name="quartierActuel"
              value={formData.quartierActuel}
              onChange={handleChange}
            >
              <option value="">Quartier/Village actuel</option>
              <option value="celony">Célony</option>
              <option value="centre ville">Centre Ville</option>
              <option value="encagnane">Encagnane</option>
              <option value="jas de bouffan">Jas de Bouffan</option>
              <option value="duranne">La Duranne</option>
              <option value="beisson">Beisson</option>
              <option value="st eutrope">St Eutrope</option>
              <option value="platanes">Les Platanes</option>
              <option value="les milles">Les Milles</option>
              <option value="luynes">Luynes</option>
              <option value="pont de beraud">Pont de Béraud</option>
              <option value="pont de l Arc">Pont de l'Arc</option>
              <option value="puyricard couteron">Puyricard - Couteron</option>
              <option value="sextius mirabeau">Sextius Mirabeau</option>
              <option value="st mitre granettes">
                St Mitre, Les Granettes, Pey Blanc
              </option>
              <option value="val st andre torse">Val St André, La Torse</option>
            </NeumorphicSelect>
          </FlexColStart>
          <FlexColStart>
            <Label>Bailleur actuel</Label>
            <NeumorphicSelect
              // required
              name="bailleurActuel"
              value={formData.bailleurActuel}
              onChange={handleChange}
            >
              <option value="">Bailleur actuel</option>
              <option value="pah">Pays d'Aix Habitat</option>
              <option value="famille et provence">Famille et Provence</option>
              <option value="logirem">Logirem</option>
              <option value="sacogiva">Sacogiva</option>
              <option value="13 habitat">13 Habitat</option>
              <option value="unicil">Unicil</option>
              <option value="prive">Privé</option>
            </NeumorphicSelect>
          </FlexColStart>
          <FlexColStart>
            <Label>Type du logement souhaité</Label>
            <NeumorphicSelect
              // required
              name="typeDeLogementSouhaite"
              value={formData.typeDeLogementSouhaite}
              onChange={handleChange}
            >
              <option value="">Type de logement souhaité</option>
              <option value="studio">Studio</option>
              <option value="t2">T2</option>
              <option value="t3">T3</option>
              <option value="t4">T4</option>
              <option value="t5">T5</option>
              <option value="t6">T6</option>
            </NeumorphicSelect>
          </FlexColStart>
          <FlexColStart>
            <AccordionContainer>
              <AccordionHeader onClick={toggleAccordion}>
                Quartier souhaité
              </AccordionHeader>
              {isAccordionOpen && (
                <AccordionContent>
                  {quartierOptions.map((quartier, index) => (
                    <CheckboxLabel key={index}>
                      <Checkbox
                        type="checkbox"
                        checked={formData.quartierSouhaite.includes(quartier)}
                        onChange={() => handleQuartierChange(quartier)}
                      />
                      {quartier}
                    </CheckboxLabel>
                  ))}
                </AccordionContent>
              )}
            </AccordionContainer>
          </FlexColStart>

          <FlexColStart>
            <Label>Motivations</Label>
            <NeumorphicTextarea
              required
              name="motivations"
              value={formData.motivations}
              onChange={handleChange}
              placeholder="Motivations"
            ></NeumorphicTextarea>
          </FlexColStart>
          <FlexColStart>
            <Label>Commentaires</Label>
            <NeumorphicTextarea
              name="commentaires"
              value={formData.commentaires}
              onChange={handleChange}
              placeholder="Commentaires"
            ></NeumorphicTextarea>
          </FlexColStart>
          {isSubmitted ? (
            <FlexRowEnd>
              <ThreeDots
                visible={true}
                height="80"
                width="80"
                color="#3457D5"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </FlexRowEnd>
          ) : (
            <FlexRowEnd>
              <NeumorphicButton type="submit">Envoyer</NeumorphicButton>
            </FlexRowEnd>
          )}

          {isSuccess && (
            <SuccessMessage>Formulaire enregistré !</SuccessMessage>
          )}
          {isError && (
            <ErrorMessage>
              Une demande existe déjà pour ce numéro unique !
            </ErrorMessage>
          )}
        </Form>
      </NeumorphicContainer>
    </div>
  );
};

export default LogementAdmin;
