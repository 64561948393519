import { get, ref } from "firebase/database";
import { database } from "../../firebase/firebase";
import { IUserData, IUserPublicData } from "./writeNewUser";

export const readUserPersonalData = async (uid: string | null) => {
  console.log(`users/${uid}/personalData`);
  let personalData: IUserData = {
    uid: "",
    pic: "",
    nom: "",
    prenom: "",
    gender: "",
    birthday: "",
    pays: "",
    ville: "",
    quartier: "",
    residence: "",
    rue: "",
    email: "",
    emailVerified: false,
    voisins: [],
    posts: [],
    events: [],
    projects: [],
  };
  try {
    await get(ref(database, `users/${uid}/personaldata`)).then((snapshot) => {
      if (snapshot.exists()) {
        const apiData = snapshot.val();
        personalData = apiData;
      } else {
        console.log(`Users doesn't exist`);
      }
    });
  } catch (error) {
    console.log(error);
  }
  return personalData;
};

export const readUserPublicData = async (uid: string | null) => {
  console.log(`users/${uid}/publicData`);
  let publicData: IUserPublicData = {
    uid: "",
    pic: "",
    nom: "",
    prenom: "",
    gender: "",
    pays: "",
    ville: "",
    quartier: "",
    emailVerified: false,
    voisins: [],
    posts: [],
    events: [],
    projects: [],
  };
  try {
    await get(ref(database, `users/${uid}/publicdata`)).then((snapshot) => {
      if (snapshot.exists()) {
        const apiData = snapshot.val();
        publicData = apiData;
      } else {
        console.log(`Users doesn't exist`);
      }
    });
  } catch (error) {
    console.log(error);
  }
  return publicData;
};
