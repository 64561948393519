import { signOut } from "firebase/auth";
import React, { useEffect } from "react";
import { auth } from "../../firebase/firebase";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { loadAuthEmpty } from "../../store/applications/auth";
import { useNavigate } from "react-router-dom";

export function logout() {
  window.localStorage.removeItem("uid-mqea");
  window.localStorage.removeItem("auth-mqea");
  return signOut(auth);
}

function Logout() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    logout().then(() => {
      dispatch(loadAuthEmpty());
      navigate("/");
    });
  }, []); // eslint-disable-line
  return <div></div>;
}

export default Logout;
